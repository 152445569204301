import React, { useEffect } from "react";
import { logout as LogOutComp } from "../../utils/auth-utils";
import CircularLoader from "../CircularLoading/CircularLoading";
import { obj } from "../../userData/UserKycStatus";
import Toast from "../../../common/components/Toast/Toast";
import { isLoggedIn } from "../../utils/cookie-utils";
import { getUserType } from "../../utils/cookie-utils";
import { closeAllSockets } from "../../../common/webSocket/WebSocket";
const Logout = ({ hardReload = false, routeTo }) => {
  let loggedOut = false;
  let redirectTo = routeTo;
  let userType = getUserType();
  {userType == 3 ? hardReload = true : hardReload = hardReload}
  {routeTo != null ? redirectTo = routeTo : userType == 3 ? redirectTo = "/?&user=consumer" : redirectTo = "/"}
  obj.isFirstLoadKYC = true;
  if (isLoggedIn()) {
    {userType == 3 ? redirectTo = "/?&user=consumer":redirectTo = "/"}
    loggedOut = LogOutComp({ hardReload: hardReload, routeTo: redirectTo });
  }
  if (!loggedOut) return <CircularLoader />;
  else {
    Toast("info", "You have been logged out!", 3000, "top-right");
    closeAllSockets();
    return true;
  }
};
export default Logout;
