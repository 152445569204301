import React from 'react';

export const setAcl = acl => {
  localStorage.setItem('AclInfo', JSON.stringify(acl));
  return true;
};

export const getAcl = () => {
  return localStorage.hasOwnProperty('AclInfo')
    ? JSON.parse(localStorage.getItem('AclInfo'))
    : false;
};

export const removeAcl = () => {
  return localStorage.hasOwnProperty('AclInfo')
    ? localStorage.removeItem('AclInfo')
    : false;
};
