import React from "react";
import ComingSoon from "../../components/ComingSoonPage/ComingSoonPage";
import OuterContainer from "../../components/OuterContainer/OuterContainer";
import UpdatePassword from "../../common/components/UpdatePassword/UpdatePassword";
export const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
let obj = {
  isFirstLoadKYC: true,
};
export { obj };
export const reactComingSoon = () => {
  return (
    <div className="-mt-17 md:m-0">
      <ComingSoon/>
    </div>
  );
};

export const comingSoonPage = () => {
  return <OuterContainer Component={reactComingSoon} />;
};
export const chnagePassword = () => {
  obj.isFirstLoadKYC = true;
  return <OuterContainer Component={UpdatePassword} />;
};
