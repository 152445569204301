import { getFiltersInfo } from "../../../utils/FiltersInfo";

export function presistantFilter(globalFilterFor) {
  let data = {},
    filterInfo = getFiltersInfo()[globalFilterFor] || {};
  if (globalFilterFor) {
    data = {
      ...(filterInfo?.dateLabel && { DateLabel: filterInfo?.dateLabel }),
      ...(filterInfo?.status && { Status: filterInfo?.status }),
      ...(filterInfo?.subStatus && { Activity: filterInfo?.subStatus }),
      ...(filterInfo?.fromDate &&
        filterInfo?.toDate && {
          Date: {
            fromDate: filterInfo?.fromDate,
            toDate: filterInfo?.toDate,
          },
        }),
      ...(filterInfo?.tagFilter && { Tag: filterInfo?.tagFilter }),
      ...(filterInfo?.campaign && { Campaign: filterInfo?.campaign }),
      ...(filterInfo?.propertyType && {
        PropertyType: filterInfo?.propertyType,
      }),
      ...(filterInfo?.projectNames && {
        PropertyName: filterInfo?.projectNames,
      }),
      ...(filterInfo?.dateFilterType && {
        DateFilterType: filterInfo?.dateFilterType,
      }),
      ...(filterInfo?.source && {
        Source: filterInfo?.source,
      }),
      ...(filterInfo?.subSource && {
        SubSource: filterInfo?.subSource,
      }),
      ...(filterInfo?.rm && {
        RM: filterInfo?.rm,
      }),
      ...(filterInfo?.sm && {
        SM: filterInfo?.sm,
      }),
      ...(filterInfo?.bpm && {
        BPM: filterInfo?.bpm,
      }),
      ...(filterInfo?.cp && {
        ChannelPartner: filterInfo?.cp,
      }),
      ...(filterInfo?.qualifyLead && {
        QualifyLead: filterInfo?.qualifyLead,
      }),
      ...(filterInfo?.lostReason && {
        LostReason: filterInfo?.lostReason,
      }),
      ...(filterInfo?.nonContactableReason && {
        NonContactableReason: filterInfo?.nonContactableReason,
      }),
      ...(filterInfo?.formType && {
        FormType: filterInfo?.formType,
      }),
      ...(filterInfo?.isActive && {
        IsActive: filterInfo?.isActive,
      }),
    };
  }
  return data;
}
