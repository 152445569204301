import { navigate } from "gatsby";
import {
  getUserType,
  setAuthCookies,
  removeAuthCookies,
  setRoleCookies,
  setTeamCookies,
  setProjectHeadCookies,
  setIsApprovedCookie,
  setAskForPasswordCookie,
} from "./cookie-utils";
import { removeAcl } from "./acl-utils";

export const setToken = async (
  { id, token, username, userType },
  routeTo = "/app/dashboard"
) => {
  setAuthCookies({ id, token, username, userType });
  //TODO: navigate(routeTo)
};
export const setRole = async role => {
  setRoleCookies(role);
};
export const setTeam = async team => {
  setTeamCookies(team);
};
export const setProjectHead = async isProjectHead => {
  setProjectHeadCookies(isProjectHead);
};
export const setIsApproved = async isApproved => {
  setIsApprovedCookie(isApproved);
}
export const setAskForPassword = async askForPassword => {
  setAskForPasswordCookie(askForPassword);
}
export const removeAllLocStorage = async () => {
  await localStorage.clear();
  return true;
};

export const logout = ({
  hardReload = window.location.pathname === "/",
  routeTo = "/",
  shouldRedirect = true,
} = {}) => {
  localStorage.removeItem("userData");
  removeAllLocStorage();
  window.localStorage.setItem("logout", Date.now()); // Log out from all windows
  if (shouldRedirect) {
    navigate(routeTo);
    removeAuthCookies();
    removeAcl();
    if (hardReload) {
      setTimeout(() => {
        location.reload(hardReload);
      }, 1000);
    }
    return true;
  }
};
