import { post, put, get, del } from "../utils/api-utils";
import Axios from "axios";
import { LemsAPI } from "../utils/api-utils";
import { baseUrl } from "gatsby-env-variables";
//update lead status

export const getLoanProduct = () =>
  get(`master/loans/product`).then(({ data }) => {
    return data;
  });

export const login = ({ phoneNumber, otpLogin, password, deviceType }) =>
  post(
    "v1/users/login",
    {
      phoneNumber,
      password,
      otpLogin,
      deviceType,
    },
    {},
    {
      "Access-Control-Expose-Headers": "Authorization",
    }
  ).then(({ data }) => data);

export const otpLogin = ({ phoneNumber }) =>
  post(
    "v1/users/login/otp",
    { phoneNumber },
    {},
    {
      "Access-Control-Expose-Headers": "Authorization",
    }
  ).then(({ data }) => data);

export const ForgotPassword = body =>
  post("users/password/forgot", body).then(({ data }) => data);

export const ForgotPasswordv1 = body =>
  post("v1/users/password/forgot/otp", body).then(({ data }) => data);

export const updatePasswordAPI = body =>
  put(
    "users/password/change",
    body,
    {},
    {
      "Access-Control-Expose-Headers": "Authorization",
      "Content-Type": "application/json",
    }
  ).then(({ data }) => data);

export const updatePasswordAPIv1 = (body, token) =>
  put(
    "v1/users/password/change",
    body,
    { token },
    {
      "Access-Control-Expose-Headers": "Authorization",
      "Content-Type": "application/json",
    }
  ).then(({ data }) => data);

export const ConfirmOTPForgotPassword = data =>
  post("users/password/confirm-otp", data).then(({ data }) => data);

export const ConfirmOTPForgotPasswordv1 = data =>
  post("v1/users/password/forgot/confirm-otp", data).then(({ data }) => data);

export const createPassword = (body, token) => {
  return put(
    "users/password",
    body,
    { token },
    {
      "Content-Type": "text/plain",
    }
  ).then(({ data }) => data);
};

export const createPasswordv1 = (body, token) => {
  return put("v1/users/password", body, { token }).then(({ data }) => data);
};

export const changePassword = body => {
  return put("v1/users/password/change", body, {
    "Content-Type": "text/plain",
  }).then(({ data }) => data);
};

export const getPBTableDataRM = (
  page,
  size,
  fromDate,
  todate,
  sort,
  sortDir,
  status,
  rmId = "",
  bpUserId = "",
  targetMonth = ""
) =>
  get(
    `dashboard/customer?from=${fromDate}&to=${todate}&page=${page}&size=${size}&sort=${sort},${sortDir}${
      status && status != "ALL" ? "&status=" + status : ""
    }&rmUserId=${rmId}&bpUserId=${bpUserId}&targetMonth=${targetMonth}`
  ).then(({ data }) => {
    return {
      data: data._embedded ? data._embedded.customers : [],
      dataPage: data.page,
    };
  });

export const GetLeadStatus = () => get("lead/status").then(({ data }) => data);

export const getListPartner = (
  page,
  size,
  fromDate,
  todate,
  sort,
  sortDir,
  rmId = ""
) =>
  get(
    "dashboard/employee/bp/list?from=" +
      fromDate +
      "&to=" +
      todate +
      "&page=" +
      page +
      "&size=" +
      size +
      "&sort=" +
      sort +
      "," +
      sortDir +
      "&rmUserId=" +
      rmId
  ).then(res => {
    return {
      data: res.data._embedded
        ? res.data._embedded.bpList
          ? res.data._embedded.bpList
          : []
        : [],
      dataPage: res.data.page,
    };
  });

export const GetAllRmList = () => get("employees/rm").then(({ data }) => data);

export const getUserProfile = () =>
  get("users/profile").then(({ data }) => data);
//Get List of States with Id
export const getStatesList = () =>
  get("master/states").then(({ data }) => data);

//Get List of Boards based on State
export const getBoardsList = state =>
  get(`master/electric-board?state=${state}`).then(({ data }) => data);

export const EmpDocUploaded = (leadId, applicantId, docId, doc) =>
  put(`lead/${leadId}/${applicantId}/profession/${docId}/${doc}/uploaded`).then(
    ({ data }) => data
  );
export const submitDetails = (leadId, applicantId, body) =>
  put(
    `lead/${leadId}/${applicantId}/profession/salaried`,
    body,
    {},
    {
      "Access-Control-Expose-Headers": "Authorization",
    }
  ).then(({ data }) => data);

////master/industry/sections
export const getIndustryTypeProfessionDetail = () =>
  get(`master/industry/sections`).then(({ data }) => {
    return data;
  });

export const getBankNameFromIFSC = ifsc =>
  Axios.get(`https://ifsc.razorpay.com/${ifsc}`).then(({ data }) => data);

export const getnatureOfBusinessLists = () =>
  get(`master/industry/sections`).then(({ data }) => data);

export const getIndustryLists = sectionId =>
  get(`master/industry/division?sectionid=${sectionId}`).then(
    ({ data }) => data
  );

export const getSubIndustryData = divID =>
  get(`master/industry/groups?divisionid=${divID}`).then(({ data }) => data);

export const getCompanyDetails = company_name =>
  get(`master/company/search?name=${company_name}`).then(({ data }) => data);

// get Bank Name to send Documents
export const getBankCities = () =>
  get(`master/bank/city`).then(({ data }) => {
    return data;
  });
// get Branch  Name to send Documents
export const getBankBranch = cityId =>
  get(`master/bank/branches/${cityId}`).then(({ data }) => {
    return data;
  });

// sep get api medial qualification
export const getDropDownOptionsAPI = name =>
  get(`master/professional-qualification?field=${name}`).then(({ data }) => {
    return data;
  });

// sep get medical council api
export const getMedicalCouncilAPI = () =>
  get(`master/medical-council`).then(({ data }) => {
    return data;
  });

// campaign otp
export const getCampaignOtp = phone =>
  post(`open/send-otp?${phone ? `phone=${phone}` : ""}`).then(
    ({ data }) => data
  );

export const consentUserCampaign = body =>
  post(
    "open/prospect/credit-consent",
    body,
    {},
    {
      "Access-Control-Expose-Headers": "Authorization",
      "Content-Type": "application/json",
    }
  ).then(({ data }) => data);

export const consentUser = body =>
  post(
    "open/prospect/",
    body,
    {},
    {
      "Access-Control-Expose-Headers": "Authorization",
      "Content-Type": "application/json",
    }
  ).then(({ data }) => data);

//get list of countries
export const getCountry = () =>
  get(`master/countries`).then(({ data }) => data);
//get list of cities
export const getCity = country =>
  get(`master/cities?country=${country}`).then(({ data }) => data);
//get list of sources
export const getSource = (filterType = "") =>
  get(
    `master/sources${filterType != "" ? `?filterType=${filterType}` : ""}`
  ).then(({ data }) => data);
//get list of sub-sources
export const getSubSource = source =>
  get(`master/sub-sources?source=${source ? source : ""}`).then(
    ({ data }) => data
  );

// get User Sources List
export const getUserSources = userId =>
  get(`user-management/users/${userId}/user-sources`).then(({ data }) => data);
// get User SubSources List
export const getUserSubSources = (userId, sourceIds) =>
  get(
    `user-management/users/${userId}/user-sources/sub-sources?sourceIds=${
      sourceIds !== undefined ? sourceIds : ""
    }`
  ).then(({ data }) => data);
// update User SubSources List
export const updateUserSources = (userId, body) =>
  put(`user-management/users/${userId}/user-sources`, body).then(
    ({ data }) => data
  );

// get project list

export const getProjectList = (propertyType = "") =>
  get(`project/current-user?propertyType=${propertyType}`).then(
    ({ data }) => data
  );
//get project opportunity list for Bp
export const getProjectOpportunityList = bpId =>
  get(`business-partner/${bpId}/project-opportunities`).then(
    ({ data }) => data
  );
// get campaign list
export const getCampaignList = projects =>
  get(`master/campaigns?${projects && `projectIds=${projects}`}`).then(
    ({ data }) => data
  );
// get BP-TYPES
export const getMasterBpTypes = () =>
  get(`master/bp-types`).then(({ data }) => data);

//get Status List
export const getMasterStatusList = () =>
  get(`master/status`).then(({ data }) => data);
//get Status List
export const getStatusList = oppId =>
  get(`master-closed/${oppId}/status`).then(({ data }) => data);
//Master Sub List
export const getMasterSubStatusList = () =>
  get(`master/sub-status`).then(({ data }) => data);
//get subStatus List
export const getSubStatusList = oppId =>
  get(`opportunity/${oppId}/sub-status-list`).then(({ data }) => data);
//get ConfigList
export const getConfigList = propertyType =>
  get(
    `master/project-config?propertyType=${
      propertyType ? propertyType : "RESIDENTIAL"
    }`
  ).then(({ data }) => data);
//get channel list
export const getTemplateChannels = () =>
  get(`master/message-template-channels`).then(({ data }) => data);
//get template types
export const getTemplateTypes = () =>
  get(`master/message-template-types`).then(({ data }) => data);
//get variable list
export const getTemplateVariables = () =>
  get(`master/message-template-variables`).then(({ data }) => data);
//get category list
export const getTemplateCategories = () =>
  get(`master/whatsapp-template-categories`).then(({ data }) => data);
// header list
export const getTemplateHeaders = () =>
  get(`master/whatsapp-template-headers`).then(({ data }) => data);
// get active use type list
export const getActiveUserTypes = () =>
  get(`master/user-types`).then(({ data }) => data);

// get active announcement type list
export const getActiveAnnouncementTypes = () =>
  get(`master/announcement-types`).then(({ data }) => data);

//get Budget List
export const getBudgetList = () =>
  get(`master/budgets`).then(({ data }) => data);
//get quality status List
export const getQualityLeadStatusList = () =>
  get(`master/quality-lead-status`).then(({ data }) => data);

export const getLocationByLocalityName = localityName =>
  get(`master/localities/?localityName=${localityName}`).then(({ data }) => {
    return data;
  });
//get lost reason list
export const getOpportunityLostReasonList = () =>
  get(`master/opportunity-lost-reasons`).then(({ data }) => data);

//get non-contactable reason list
export const getOpportunityNonContactableReasonList = () =>
  get(`master/opportunity-non-contactable-reasons`).then(({ data }) => data);

export const getAllDeviceType = () =>
  get(`master/device-types`).then(({ data }) => data);

//update opportunity project
export const updateOpportunity = (opportunityId, body) =>
  put(`opportunity/${opportunityId}`, body).then(({ data }) => data);

// getOpportunity
export const getOpportunity = (
  sort,
  sortDir,
  dateFilterType,
  startDate,
  endDate,
  filterSource,
  filterSubSource,
  filterStatus,
  filterSubStatus,
  filterTag,
  propertyTypeSelected,
  projectNames,
  rmSelected,
  campaignNames,
  filterQualifyLead,
  filterLostReason,
  filterNonContactableReason,
  fbAdIds,
  searchText,
  pageNo = 0,
  Rows,
  fromReport = false,
  cpSelected,
  leadBucketId,
  showExpiredLead = false,
  showVerifiedLead,
  nullProject = false
) => {
  return get(
    `opportunity?paged=true&sort=${sort},${sortDir}${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${
      dateFilterType >= 0 ? `&dateFilterType=${dateFilterType}` : ``
    }${filterSource && `&source=${filterSource}`}${
      filterSubSource && `&subSources=${filterSubSource}`
    }${filterStatus && `&status=${filterStatus}`}${
      filterSubStatus && `&subStatus=${filterSubStatus}`
    }${rmSelected && `&users=${rmSelected}`}${
      cpSelected && `&businessPartners=${cpSelected}`
    }${campaignNames && `&campaigns=${campaignNames}`}${
      propertyTypeSelected && `&propertyType=${propertyTypeSelected}`
    }${projectNames && `&projects=${projectNames}`}${
      filterTag !== "" ? `&tag=${filterTag}` : ``
    }${filterQualifyLead && `&qualityLeadStatus=${filterQualifyLead}`}${
      filterLostReason && `&opportunityLostReasons=${filterLostReason}`
    }${
      filterNonContactableReason &&
      `&nonContactableReasons=${filterNonContactableReason}`
    }${fbAdIds.length > 0 ? `&fbAdIds=${fbAdIds}` : ""}${
      searchText && `&search=${searchText}`
    }${`&page=${pageNo}`}${
      Rows && `&size=${Rows}`
    }${`&fromReport=${fromReport}`}${`&isExpire=${showExpiredLead}`}${
      leadBucketId ? `&leadBucket=${leadBucketId}` : ``
    }${
      showVerifiedLead != null ? `&isVerified=${showVerifiedLead}` : ``
    }${`&nullProject=${nullProject}`}`
  ).then(({ data }) => data);
};

// get opportunity pagination details
export const getOpportunityPagination = (
  sort,
  sortDir,
  dateFilterType,
  startDate,
  endDate,
  filterSource,
  filterSubSource,
  filterStatus,
  filterSubStatus,
  filterTag,
  propertyTypeSelected,
  projectNames,
  rmSelected,
  campaignNames,
  filterQualifyLead,
  filterLostReason,
  filterNonContactableReason,
  fbAdIds,
  searchText,
  pageNo = 0,
  Rows,
  fromReport = false,
  cpSelected,
  leadBucketId,
  showExpiredLead = false,
  showVerifiedLead,
  nullProject = false
) => {
  return get(
    `opportunity/page?forceFirstAndLastRels=true&paged=true&sort=${sort},${sortDir}${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${
      dateFilterType >= 0 ? `&dateFilterType=${dateFilterType}` : ``
    }${filterSource && `&source=${filterSource}`}${
      filterSubSource && `&subSources=${filterSubSource}`
    }${filterStatus && `&status=${filterStatus}`}${
      filterSubStatus && `&subStatus=${filterSubStatus}`
    }${rmSelected && `&users=${rmSelected}`}${
      cpSelected && `&businessPartners=${cpSelected}`
    }${campaignNames && `&campaigns=${campaignNames}`}${
      propertyTypeSelected && `&propertyType=${propertyTypeSelected}`
    }${projectNames && `&projects=${projectNames}`}${
      filterTag !== "" ? `&tag=${filterTag}` : ``
    }${filterQualifyLead && `&qualityLeadStatus=${filterQualifyLead}`}${
      filterLostReason && `&opportunityLostReasons=${filterLostReason}`
    }${
      filterNonContactableReason &&
      `&nonContactableReasons=${filterNonContactableReason}`
    }${fbAdIds.length > 0 ? `&fbAdIds=${fbAdIds}` : ""}${
      searchText && `&search=${searchText}`
    }${`&page=${pageNo}`}${
      Rows && `&size=${Rows}`
    }${`&fromReport=${fromReport}`}${`&isExpire=${showExpiredLead}`}${
      leadBucketId ? `&leadBucket=${leadBucketId}` : ``
    }${
      showVerifiedLead != null ? `&isVerified=${showVerifiedLead}` : ``
    }${`&nullProject=${nullProject}`}`
  ).then(({ data }) => data);
};
// get opportunity Details
export const getOppDetails = id =>
  get(`opportunity/${id}`).then(({ data }) => data);
// get campaign details in oppDetails
export const getOppCampaignDetails = id =>
  get(`opportunity/${id}/details/campaign`).then(({ data }) => data);
//add opportunity
export const addOpportunity = body =>
  post(`opportunity/`, body).then(({ data }) => data);

//edit opportunity
export const editOpportunity = (id, body) =>
  put(`opportunity/${id}`, body).then(({ data }) => data);

//update Offer Amount
export const updateOfferedAmount = (oppId, body) =>
  put(`opportunity/${oppId}/offered-amount`, body).then(({ data }) => data);
//add activity api
export const addActivity = body =>
  post(`activity`, body).then(({ data }) => data);
//get Activity
export const getActivity = id =>
  get(`activity/${id}/list`).then(({ data }) => data);

export const getCallLogDetails = callLogId =>
  get(`v1/calls/${callLogId}`).then(({ data }) => data);
// Add status
//add activity api
export const addStatus = body =>
  post(`activity/status`, body).then(({ data }) => data);
//get reasons
export const getReasonList = () =>
  get(`master/status-reason`).then(({ data }) => data);
//get tags
export const getTagList = () => get(`master/tags`).then(({ data }) => data);
//get the rm/bp re-assign list.
export const getAvailableListsForReassign = (id, userType = "opp") =>
  userType === "opp"
    ? get(`opportunity/reassign/options?${`opportunityId=${id}`}`).then(
        ({ data }) => data
      )
    : userType === "bp" &&
      get(`business-partner/${id}/reassign-options`).then(({ data }) => data);

export const assigneNewOwner = (id, ownerId, userType = "opp") =>
  userType === "opp"
    ? put(`opportunity/${id}/reassign?newOwnerId=${ownerId}`).then(
        ({ data }) => data
      )
    : userType === "bp" &&
      put(`business-partner/${id}/reassign/${ownerId}`).then(
        ({ data }) => data
      );
export const getPinCodeInfo = pincode => {
  return Axios.get(`https://api.postalpincode.in/pincode/${pincode}`)
    .then(({ data }) => ({
      isAxiosError: false,
      data,
    }))
    .catch(err => {
      return {
        isAxiosError: true,
        data: getFromLodash(err, "response.data", {}),
      };
    });
};
//add Account
export const addAccount = body =>
  post(`accounts`, body).then(({ data }) => data);

//Get Account List

export const getAccountList = (
  startDate,
  endDate,
  searchText,
  pageNo,
  pageSize,
  sort = "createdOn",
  sortDir = "desc"
) => {
  return get(
    `accounts?forceFirstAndLastRels=true&paged=true&${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${searchText && `&search=${searchText}`}${
      pageNo !== "" ? `&page=${pageNo}` : ``
    }${pageSize && `&size=${pageSize}`}&sort=${sort},${sortDir}`
  ).then(({ data }) => {
    if (Array.isArray(data?._embedded?.accountListDtoList)) {
      data?._embedded?.accountListDtoList?.map((each, index) => {
        each["serial_number"] = parseInt(pageNo) * pageSize + index + 1;
      });
      return data;
    } else [];
  });
};
//get Account Profile
export const getAccountProfile = accountId =>
  get(`accounts/${accountId}`).then(({ data }) => data);

//get Opportunities base on account
export const getOpportunityByAccount = (accountId, pageNo, pageSize, status) =>
  get(
    `accounts/${accountId}/opportunities?forceFirstAndLastRels=true&paged=true${
      pageSize && `&size=${pageSize}`
    }${pageNo !== "" ? `&page=${pageNo}` : ``}${
      status ? `&status=${status}` : ""
    }`
  ).then(({ data }) => data);

//edit profile
export const editAccountProfile = (id, body) =>
  put(`accounts/${id}`, body).then(({ data }) => data);

export const getCompanyCategory = () =>
  get(`master/company-category`).then(({ data }) => data);

// Add WalkIn

//add Account
export const createWalkInForm = projectId =>
  post(`walk-in?projectId=${projectId}`).then(({ data }) => data);

//Publish and UnPublish Walk-in
export const publishWalkInForm = (token, body) =>
  post(`walk-in/form/${token}?publish=${body}`, body).then(({ data }) => data);

// WalkIn Dashboard API

export const getWalkInList = (
  pageNo = 0,
  pageSize = 20,
  sort = "createdOn",
  sortDir = "desc",
  projectNames
) => {
  return get(
    `walk-in?forceFirstAndLastRels=true&paged=true${
      pageNo !== "" ? `&page=${pageNo}` : ``
    }${
      projectNames != null && projectNames != ""
        ? `&projects=${projectNames}`
        : ``
    }${pageSize && `&size=${pageSize}`}&sort=${sort},${sortDir}`
  ).then(({ data }) => {
    if (Array.isArray(data?._embedded?.walkInListDtoList)) {
      data?._embedded?.walkInListDtoList?.map((each, index) => {
        each["serial_number"] = parseInt(pageNo) * pageSize + index + 1;
      });
      return data;
    } else [];
  });
};
// getOpportunity
export const getPerformanceReport = (
  startDate,
  endDate,
  users,
  projectNames,
  endPoint = "activity",
  propertyTypeSelected,
  pageNo,
  Rows,
  sortCol = "totalCount",
  sortDirection = "asc"
) => {
  return get(
    `report/performance/${endPoint}?unpaged=true${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${
      propertyTypeSelected && `&propertyType=${propertyTypeSelected}`
    }${projectNames && `&projects=${projectNames}`}${
      users !== "" ? `&users=${users}` : ``
    }${pageNo !== undefined ? `&page=${pageNo}` : ""}${
      Rows ? `&size=${Rows}` : ""
    }${sortCol ? `&sort=${sortCol}` : ""},${
      sortDirection && `${sortDirection}`
    }`
  ).then(({ data }) => data);
};
export const getRmLists = () => {
  return get(`users/list`).then(({ data }) => data);
};
export const getSubOrgRMList = () => {
  return get(`user-management/users/all-sub-org`).then(({ data }) => data);
};
export const getCPLists = (search = "", bpTypeId = 0) => {
  return get(
    `business-partner/filter/user?search=${
      search !== undefined ? search : ""
    }&bpTypeId=${bpTypeId}`
  ).then(({ data }) => data);
};

export const getBpListForCurrentUser = (search = "", bpType = 0) => {
  return get(
    `business-partner/filter?search=${
      search !== undefined ? search : ""
    }&bpTypeId=${bpType}`
  ).then(({ data }) => data);
};

export const getFormValidation = walkInToken => {
  return get(`walk-in/validate?token=${walkInToken}`).then(({ data }) => data);
};

//add walkin form info
export const addConsumerInfo = (body, token) =>
  post(`consumer/${token}/information`, body).then(({ data }) => data);

//update walkin form info
export const updateConsumerInfo = (body, token) =>
  put(`consumer/${token}/information`, body).then(({ data }) => data);

//get walkin form info
export const getWalkinFormInfo = (opportunityId, token) =>
  get(`consumer/${token}/information/?opportunityId=${opportunityId}`).then(
    ({ data }) => data
  );

export const consumerSendOtp = (ph, token) =>
  post(`consumer/${token}/send-otp?phone=${ph}`).then(({ data }) => data);

export const consumerVerifyOtp = (body, token) =>
  post(`consumer/${token}/verify-otp`, body).then(({ data }) => data);

export const getConflictedOpportunityListForWalkIn = (
  token,
  phone,
  searchText,
  sourceId,
  pageNo,
  pageSize
) =>
  get(
    `consumer/${token}/conflict/opportunities?forceFirstAndLastRels=true&paged=true${
      pageSize && `&size=${pageSize}`
    }${`&page=${pageNo}`}&phoneNumber=${phone}
    ${searchText && `&search=${searchText}`} ${
      sourceId && `&sourceId=${sourceId}`
    }`
  ).then(({ data }) => data);

//get list of RM for walk in From
export const getRMListForWalkIn = oppId =>
  get(`consumer/${oppId}/reassign/options`).then(({ data }) => data);

// Reassigns the walk in opp to given rm
export const ReassignRMWalkInOpportunity = (oppId, newOwnerId) =>
  put(`consumer/${oppId}/reassign?newOwnerId=${newOwnerId}`);

// BP List
export const getBPList = (
  startDate,
  endDate,
  searchText,
  pageNo,
  pageSize,
  sort = "",
  sortDir = "desc",
  bpmSelected,
  smSelected,
  projects,
  bpStatusSelected,
  isProjectHead,
  status,
  allLeads,
  isActive = true,
  bpTypeId = 0
) => {
  return get(
    `business-partner/dashboard/business-partners?forceFirstAndLastRels=true&paged=true&${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${searchText && `&search=${searchText}`}${
      pageNo !== "" ? `&page=${pageNo}` : ``
    }${pageSize && `&size=${pageSize}`}&sort=${sort},${sortDir}${
      bpmSelected && `&businessPartnerManagerIds=${bpmSelected}`
    }${smSelected && `&salesManagerIds=${smSelected}`}${
      bpStatusSelected && `&bpStatus=${bpStatusSelected}`
    }${isProjectHead ? `&headProjects=${isProjectHead}` : ""}${
      status ? `&statusId=${status}` : ``
    }${allLeads ? `&allLeads=${allLeads}` : ``}&isActive=${isActive}${
      projects && `&projects=${projects}`
    }${bpTypeId != undefined && bpTypeId != null && `&bpTypeId=${bpTypeId}`}`
  ).then(({ data }) => data);
};
export const getBPDashboardTotalCount = (
  startDate,
  endDate,
  searchText,
  bpmSelected,
  smSelected,
  projects,
  bpStatusSelected,
  isProjectHead,
  isActive = true,
  bpTypeId = 0
) => {
  return get(
    `business-partner/dashboard/total-counts?${
      startDate != null ? `from=${startDate}` : ``
    }${endDate != null ? `&to=${endDate}` : ``}${
      searchText && `&search=${searchText}`
    }${bpmSelected && `&businessPartnerManagerIds=${bpmSelected}`}${
      smSelected && `&salesManagerIds=${smSelected}`
    }${bpStatusSelected && `&bpStatus=${bpStatusSelected}`}${
      isProjectHead ? `&headProjects=${isProjectHead}` : ""
    }&isActive=${isActive}&bpTypeId=${bpTypeId}${
      projects && `&projects=${projects}`
    }`
  ).then(({ data }) => data);
};

export const getBPDashboardSubStatusCount = (startDate, endDate, projects) => {
  return get(
    `business-partner/dashboard/sub-status?${
      startDate != null ? `from=${startDate}` : ``
    }${endDate != null ? `&to=${endDate}` : ``}${
      projects && `&projects=${projects}`
    }`
  ).then(({ data }) => data);
};

export const getBPDashboardStatusCount = (startDate, endDate, projects) => {
  return get(
    `business-partner/dashboard/status?${
      startDate != null ? `from=${startDate}` : ``
    }${endDate != null ? `&to=${endDate}` : ``}${
      projects && `&projects=${projects}`
    }`
  ).then(({ data }) => data);
};

export const getBPDashboardStatusTotalCount = () => {
  return get(`business-partner/dashboard/status/total`).then(
    ({ data }) => data
  );
};

//get teams list from search
export const getTeamList = searchText =>
  get(
    `master-closed/user-teams?search=${searchText}
    `
  ).then(({ data }) => data);
// get role list from teamIds and search
export const getRoleList = (searchText, teamSelectedId) =>
  get(
    `master-closed/user-roles?search=${searchText}${
      teamSelectedId.length > 0 ? `&userTeamsIds=${teamSelectedId}` : ""
    }
    `
  ).then(({ data }) => data);

export const getBPMList = (searchText, isProjectHead, bpTypeId = 0) =>
  get(
    `business-partner/managers?search=${searchText}${
      isProjectHead ? `&headProjects=${isProjectHead}` : ""
    }&bpTypeId=${bpTypeId}`
  ).then(({ data }) => data);

export const getBPSMList = (searchText, isProjectHead, bpTypeId = 0) =>
  get(
    `business-partner/sales-managers?search=${searchText}${
      isProjectHead ? `&headProjects=${isProjectHead}` : ""
    }&bpTypeId=${bpTypeId}`
  ).then(({ data }) => data);

//add Account
export const addBP = body =>
  post(`business-partner`, body).then(({ data }) => data);
// getWalkInResponse
export const getWalInResponse = (
  walkInFormId,
  sort = "responseDate",
  sortDir = "desc",
  pageSize,
  startDate,
  endDate,
  searchText,
  pageNo,
  users
) =>
  get(
    `walk-in/${walkInFormId}/responses?forceFirstAndLastRels=true&&${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${
      pageNo !== "" ? `&pageNumber=${pageNo}` : ``
    }${pageSize && `&pageSize=${pageSize}`}&sort=${sort},${sortDir}&paged=true${
      searchText && `&search=${searchText}`
    }&users=${users}`
  ).then(({ data }) => {
    if (Array.isArray(data?._embedded?.walkInResponseDtoList)) {
      data?._embedded?.walkInResponseDtoList?.map((each, index) => {
        each["serial_number"] = parseInt(pageNo) * pageSize + index + 1;
      });
    }
    return {
      data: data?._embedded?.walkInResponseDtoList
        ? data?._embedded?.walkInResponseDtoList
        : [],
      dataPage: data?.page,
    };
  });
//get feedback-params
export const getFeedbackParams = () =>
  get(`master/customer-feedback-parameters`).then(({ data }) => {
    return data;
  });

//save feedback
export const addFeedback = (opportunityId, body) =>
  post(`feedback/${opportunityId}`, body).then(({ data }) => data);

//get feedback
export const getFeedback = (opportunityId, formId) =>
  get(`feedback/${opportunityId}?formId=${formId}`).then(({ data }) => data);

//Get List of Boards based on State
export const getSubsourceWalkIn = state =>
  get(`master/sub-sources/walk-in`).then(({ data }) => data);

//get opp details in feedback
export const getOppDetailsofFeedback = id =>
  get(`opportunity/${id}/feedback-form-opp-details`).then(({ data }) => data);
//get weekend-visibility-report
export const getWeekendVisibilityReport = (
  startDate,
  endDate,
  pageNo,
  pageSize,
  users,
  sortCol,
  sortDirection,
  projectNames,
  reportBy = false
) =>
  get(
    `report/weekend-visibility-report?unpaged=false${
      sortCol && `&sort=${sortCol}`
    },${sortDirection && `${sortDirection}`}${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${
      projectNames && `&projects=${projectNames}`
    }${users !== "" ? `&users=${users}` : ``}${
      reportBy ? `&entityCategoryId=${reportBy}` : ""
    }${pageNo !== undefined ? `&page=${pageNo}` : ""}${
      pageSize ? `&size=${pageSize}` : ""
    }`
  ).then(({ data }) => data);

//get weekend-visibility-report-total-count
export const getWeekendVisibilityReporTotalCount = (
  startDate,
  endDate,
  users,
  projectNames,
  reportBy = false
) =>
  get(
    `report/weekend-visibility-report/total-count?unpaged=true${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${
      projectNames && `&projects=${projectNames}`
    }${users !== "" ? `&users=${users}` : ``}${
      reportBy ? `&entityCategoryId=${reportBy}` : ""
    }`
  ).then(({ data }) => data);

//get Inbound-call-report
export const getInboundCallReport = (
  startDate,
  endDate,
  users,
  projectNames,
  pageNo,
  Rows,
  sortCol = "totalCount",
  sortDirection = "desc",
  reportBy = 0
) =>
  get(
    `report/inbound-call?${startDate && `&from=${startDate}`}${
      endDate && `&to=${endDate}`
    }${projectNames != undefined ? `&projects=${projectNames}` : ""}${
      users !== "" ? `&users=${users}` : ``
    }${`&entityCategoryId=${reportBy}`}${
      pageNo !== undefined ? `&page=${pageNo}` : ""
    }${Rows ? `&size=${Rows}` : ""}${sortCol ? `&sort=${sortCol}` : ""},${
      sortDirection && `${sortDirection}`
    }`
  ).then(({ data }) => data);

// get Inbound-call-report chart count data
export const getInboundCallReportCountData = (
  startDate,
  endDate,
  users,
  projectNames,
  reportBy = 0
) =>
  get(
    `report/inbound-call/total-counts?${startDate && `&from=${startDate}`}${
      endDate && `&to=${endDate}`
    }${projectNames != undefined ? `&projects=${projectNames}` : ""}${
      users !== "" ? `&users=${users}` : ``
    }${`&entityCategoryId=${reportBy}`}`
  ).then(({ data }) => data);

// Exotel Call

export const makeACall = oppId =>
  post(`calling/${oppId}`, {
    "Access-Control-Expose-Headers": "Authorization",
    "Content-Type": "application/json",
  }).then(({ data }) => data);

export const bpStatus = (bpId, body) =>
  put(`business-partner/status/${bpId}`, body).then(({ data }) => data);
// Lead Bucket Report

export const getEntityCategories = () =>
  get(`master/entity-categories`).then(({ data }) => data);

export const getLeadBucketReport = (
  startDate,
  endDate,
  users,
  filterSource,
  filterSubSource,
  propertyTypeSelected,
  projectNames,
  filterQualifyLead,
  filterLostReason,
  reportBy,
  endPoint = "activity",
  showBucketOpportunity = false,
  pageNo,
  pageSize,
  sortCol,
  sortDirection
) => {
  return get(
    `report/lead-bucket/${endPoint}?forceFirstAndLastRels=true&paged=true${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${
      projectNames && `&projects=${projectNames}`
    }${users !== "" ? `&users=${users}` : ``}${
      filterSource && `&source=${filterSource}`
    }${filterSubSource && `&subSources=${filterSubSource}`}${
      propertyTypeSelected && `&propertyType=${propertyTypeSelected}`
    }${reportBy != undefined ? `&entityCategoryId=${reportBy}` : ""}${
      filterQualifyLead && `&qualityLeadStatus=${filterQualifyLead}`
    }${
      filterLostReason && `&opportunityLostReasons=${filterLostReason}`
    }${`&showBucketOpportunity=${showBucketOpportunity}`}${
      pageNo !== undefined ? `&page=${pageNo}` : ""
    }${pageSize ? `&size=${pageSize}` : ""}${
      sortCol ? `&sort=${sortCol}` : ""
    }${sortDirection !== undefined ? `,${sortDirection}` : ``}`
  ).then(({ data }) => data);
};
// download lead bucket report excel
export const downloadLeadBucketReportExcel = (
  startDate,
  endDate,
  users,
  filterSource,
  filterSubSource,
  propertyTypeSelected,
  projectNames,
  filterQualifyLead,
  filterLostReason,
  reportBy,
  endPoint = "activity",
  showBucketOpportunity = false,
  requestOptions
) => {
  return fetch(
    `${baseUrl}/report/lead-bucket/${endPoint}/excel?${
      startDate && `from=${startDate}`
    }${endDate && `&to=${endDate}`}${
      projectNames && `&projects=${projectNames}`
    }${users !== "" ? `&users=${users}` : ``}${
      filterSource && `&source=${filterSource}`
    }${filterSubSource && `&subSources=${filterSubSource}`}${
      propertyTypeSelected && `&propertyType=${propertyTypeSelected}`
    }${reportBy != undefined ? `&entityCategoryId=${reportBy}` : ""}${
      filterQualifyLead && `&qualityLeadStatus=${filterQualifyLead}`
    }${
      filterLostReason && `&opportunityLostReasons=${filterLostReason}`
    }${`&showBucketOpportunity=${showBucketOpportunity}`}`,
    requestOptions
  );
};
// consumer sign-up send otp
export const sendOtpToConsumer = body =>
  post(`consumers/sign-up/otp`, body).then(({ data }) => data);
//consumer otp verification
export const verifyConsumerOtp = body =>
  post(`consumers/sign-up/confirm-otp`, body).then(({ data }) => data);
// bp send otp
export const sendOtpToBp = (ph, bpType = 0) =>
  post(`business-partner/onboard/send-otp?phone=${ph}&bpTypeId=${bpType}`).then(
    ({ data }) => data
  );
//bp otp verification
export const verifyBpOtp = body =>
  post(`business-partner/onboard/verify-otp`, body).then(({ data }) => data);
//commonapi's for bp operations
export const bpCommonApi = (endpath, body, method = post, headers) => {
  return method(
    `business-partner/onboard/${endpath}`,
    typeof body === "object" && body !== null ? body : `${body}`,
    undefined,
    headers && headers
  ).then(({ data }) => data);
};
// upload Bp-onboard pan data
export const uploadPANData = body =>
  post(`business-partner/onboard/upload-pan`, body).then(({ data }) => data);

export const bpOnboardUploadRERA = RERANumber =>
  post(`business-partner/onboard/upload-rera?reraNumber=${RERANumber}`).then(
    ({ data }) => data
  );
export const bpOnboardUploadGST = GSTNumber =>
  post(
    `business-partner/onboard/upload-gstin${
      GSTNumber !== null ? `?gstin=${GSTNumber}` : ""
    }`
  ).then(({ data }) => data);
//check bp doc uploaded
export const bpDocUploaded = docId =>
  post(`business-partner/onboard/${docId}/uploaded`).then(({ data }) => data);

//re-upload doc for bp profile
export const bpReUploadDoc = (bpId, body) =>
  post(`business-partner/${bpId}/upload-url`, body).then(({ data }) => data);

//check doc re-uploaded for bp profile
export const bpDocReUploaded = (bpId, docId) =>
  post(`business-partner/${bpId}/uploaded/${docId}`).then(({ data }) => data);

//get OCR details of PAN Card for bp profile
export const bpGetOCRDetails = (bpId, docId) =>
  put(`business-partner/${bpId}/pan/${docId}`).then(({ data }) => data);

//verify PAN Details for bp profile
export const bpPANVerify = (bpId, body) =>
  put(`business-partner/${bpId}/pan/verify`, body).then(({ data }) => data);

//change bp isActive status
export const bpIsActiveStatus = (bpId, isActive) =>
  put(`business-partner/${bpId}/isActive?isActive=${isActive}`).then(
    ({ data }) => data
  );

export const bpDndStatus = (bpId, dnd) =>
  put(`business-partner/${bpId}/dnd?dnd=${dnd}`).then(({ data }) => data);

//add teammate to bp
export const addBPTeammate = (bpId, body) =>
  post(`business-partner/teammates${bpId ? `/${bpId}` : ``}`, body).then(
    ({ data }) => data
  );

//edit teammate to bp
export const editBPTeammate = (bpTeammateId, body) =>
  put(`business-partner/teammates/${bpTeammateId}`, body).then(
    ({ data }) => data
  );

//get bp teammate list
export const getBPteammateList = bpId =>
  get(`business-partner/teammates${bpId ? `/${bpId}` : ``}`).then(
    ({ data }) => data
  );

//api to upload files to s3
export const UploadFileToS3 = (
  url,
  file,
  contentType = { "Content-Type": "application/json" },
  onUploadProgress = () => {}
) =>
  LemsAPI.put(url, file, {
    headers: { ...contentType },
    onUploadProgress: onUploadProgress,
  }).then(({ data }) => data);

//get bp details with PAN and RERA
export const getBPDetailsWithDocs = id =>
  get(`business-partner/${id}/details`).then(({ data }) => data);
//get bp details
export const getBPDetails = id =>
  get(`users/profile/bp/${id}`).then(({ data }) => data);
//Edit BP Details
export const editBpDetails = (bpId, body) =>
  put(`business-partner/${bpId}`, body).then(({ data }) => data);
//get BP profile metrics data
export const getBPLeadSummaryData = (bpId, projectId) =>
  get(
    `business-partner/${bpId}/bp-profile-metrices?projectId=${projectId}`
  ).then(({ data }) => data);
//get doc for BP
export const getBPDocs = (docTypeId, bpId) =>
  get(`business-partner/doc-read?docTypeId=${docTypeId}&bpId
=${bpId}`).then(({ data }) => data);
//get followUp count
export const getFUCount = () =>
  get(`opportunity/dashboard/counts`).then(({ data }) => data);
//get follow up dashboard list
export const getFUList = (
  startDate,
  endDate,
  pageNo,
  pageSize,
  users,
  sortCol,
  sortDirection
) =>
  get(
    `opportunity/dashboard/followups?paged=true${
      sortCol && `&sort=${sortCol}`
    },${sortDirection && `${sortDirection}`}${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${pageNo !== "" ? `&page=${pageNo}` : ``}${
      pageSize && `&size=${pageSize}`
    }${users !== "" ? `&rmUserList=${users}` : ``}`
  ).then(({ data }) => data);
//get un serviced list
export const getUnservicedList = (
  startDate,
  endDate,
  pageNo,
  pageSize,
  users,
  sortCol,
  sortDirection
) =>
  get(
    `opportunity/dashboard/re-inquired?paged=true${
      sortCol && `&sort=${sortCol}`
    },${sortDirection && `${sortDirection}`}${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${pageNo !== "" ? `&page=${pageNo}` : ``}${
      pageSize && `&size=${pageSize}`
    }${users !== "" ? `&rmUserList=${users}` : ``}`
  ).then(({ data }) => data);
//get Performing Campaigns in dashboard
export const getPerformingCampaignReport = (
  startDate,
  endDate,
  pageNo,
  pageSize,
  sortCol,
  sortDirection
) =>
  get(
    `opportunity/dashboard/top-performing-campaign?paged=true${
      sortCol && `&sort=${sortCol}`
    }${sortDirection && `${sortDirection}`}${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${pageNo !== "" ? `&page=${pageNo}` : ``}${
      pageSize && `&size=${pageSize}`
    }`
  ).then(({ data }) => data);

//getting callReport
export const getCallReport = (
  startDate,
  endDate,
  pageNo,
  pageSize,
  users,
  sortCol,
  sortDirection
) =>
  get(
    `report/call-report?paged=true${sortCol && `&sort=${sortCol}`},${
      sortDirection && `${sortDirection}`
    }${startDate && `&from=${startDate}`}${endDate && `&to=${endDate}`}${
      pageNo !== "" ? `&page=${pageNo}` : ``
    }${pageSize && `&size=${pageSize}`}${users !== "" ? `&users=${users}` : ``}`
  ).then(({ data }) => {
    if (Array.isArray(data?._embedded?.employeeCallReportDtoList)) {
      data?._embedded?.employeeCallReportDtoList?.map((each, index) => {
        each["serial_number"] = parseInt(pageNo) * pageSize + index + 1;
      });
      return data;
    } else [];
  });
// master RM List
export const getRMMasterList = () => get(`master/rm`).then(({ data }) => data);

// Walkin real time report
export const getWalkinReport = (
  endPoint = "walk-in/real-time-responses",
  startDate = "",
  endDate = "",
  walkInFormId = ""
) => {
  return get(
    `${endPoint}${startDate && `?from=${startDate}`}${
      endDate && `&to=${endDate}`
    }${walkInFormId && `&walkInFormId=${walkInFormId}`}`
  ).then(({ data }) => data);
};
export const getWalkInReportRMList = (startDate = "", endDate = "") => {
  return get(
    `walk-in/by-rm${startDate && `?from=${startDate}`}${
      endDate && `&to=${endDate}`
    }`
  ).then(({ data }) => data);
};
export const getRejectionReason = () =>
  get("master/bp-rejection").then(({ data }) => data);

// User Details API

export const getUserDetails = () =>
  get(`users/details`).then(({ data }) => data);

//get RM count
export const getRMCount = () =>
  get(`lead-flow-config/project-rm-counts`).then(({ data }) => data);

//get accept-lead
export const getAcceptLead = () =>
  get(`users/accept-lead`).then(({ data }) => data);

//save accept-lead
export const saveAcceptLead = body =>
  put(`users/accept-lead`, body).then(({ data }) => data);

// get Opportunities of a perticular bp
export const getBPOpportunities = (
  bpId,
  pageNo,
  pageSize,
  status,
  projectNames
) =>
  get(
    `business-partner/${bpId}/opportunities?forceFirstAndLastRels=true&paged=true${
      pageSize && `&size=${pageSize}`
    }${pageNo !== "" ? `&page=${pageNo}` : ``}${
      status.length > 0 && status[0] != 0 ? `&status=${status}` : ""
    }${projectNames != "" ? `&projects=${projectNames}` : ``}`
  ).then(({ data }) => data);

// add rm for perticular project
export const addRMForParticularProject = (projectId, body) =>
  put(`lead-flow-config/${projectId}`, body).then(({ data }) => data);

// get Source
export const getRmSource = () =>
  get(`master/lead-config/sources`).then(({ data }) => data);

//get all lead flow configs
export const getLeadFlow = projectId =>
  get(`lead-flow-config?projectId=${projectId}`).then(({ data }) => data);

// whatsApp Notification

export const notificationGetAPI = ({
  pageNo,
  perPageResult,
  startDate,
  endDate,
}) =>
  get(
    `notification?page=${pageNo ? pageNo : 0}&size=${
      perPageResult ? perPageResult : ""
    }${startDate ? `&from=${startDate}` : ``}${endDate ? `&to=${endDate}` : ``}`
  ).then(({ data }) => data);

export const notificationAsReadAPI = (notificationId, allRead = false) =>
  post(
    `notification/mark-read${
      !allRead ? (notificationId ? `/${notificationId}` : "") : ""
    }`
  ).then(({ data }) => data);

export const getNotificationCount = () =>
  get(`notification/count`).then(({ data }) => data);

//getting miss-callReport
export const getMissCallReport = (
  startDate,
  endDate,
  pageNo,
  pageSize,
  users,
  sortCol,
  sortDirection
) =>
  get(
    `report/missed-call-report?paged=true${sortCol && `&sort=${sortCol}`},${
      sortDirection && `${sortDirection}`
    }${startDate && `&from=${startDate}`}${endDate && `&to=${endDate}`}${
      pageNo !== "" ? `&page=${pageNo}` : ``
    }${pageSize && `&size=${pageSize}`}${users !== "" ? `&users=${users}` : ``}`
  ).then(({ data }) => {
    if (Array.isArray(data?._embedded?.missedCallReportDtoList)) {
      data?._embedded?.missedCallReportDtoList?.map((each, index) => {
        each["serial_number"] = parseInt(pageNo) * pageSize + index + 1;
      });
      return data;
    } else [];
  });

////get follow up
export const getFollowUpReport = ({ startDate, endDate, users, reportBy }) => {
  return get(
    `report/follow-up-report?unpaged=true${startDate && `&from=${startDate}`}${
      endDate && `&to=${endDate}`
    }${users !== "" ? `&users=${users}` : ``}${
      reportBy && reportBy !== undefined ? `&projectWise=true` : ""
    }
      `
  ).then(({ data }) => data);
};
//api for mass assignment
export const massAssignment = (oppIds, newOwnerId) =>
  post(
    `opportunity/reassign?${`opportunityIds=${oppIds}`}${`&newOwnerId=${newOwnerId}`}`
  ).then(({ data }) => data);

// api for mass unexpire leads
export const massUnexpireLead = (oppIds, isExpire) =>
  post(
    `lead-expiry-buckets/expire/status?${`opportunityIds=${oppIds}`}${`&isExpire=${isExpire}`}`
  ).then(({ data }) => data);

export const getLocation = cityId =>
  get(`master/localities/?city=${cityId}`).then(({ data }) => {
    return data;
  });
//Get All Customer Event Report
export const getAllCustomerEventReport = (
  startDate,
  endDate,
  pageNo,
  pageSize,
  sortCol,
  sortDirection,
  users,
  projectNames
) =>
  get(
    `report/re-inquiry?forceFirstAndLastRels=true&paged=true${
      sortCol && `&sort=${sortCol}`
    },${sortDirection && `${sortDirection}`}${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${pageNo !== "" ? `&page=${pageNo}` : ``}${
      pageSize && `&size=${pageSize}`
    }${users !== "" ? `&users=${users}` : ``}${
      projectNames && `&projects=${projectNames}`
    }`
  ).then(({ data }) => {
    if (Array.isArray(data?._embedded?.reEnquiryReportDtoList)) {
      data?._embedded?.reEnquiryReportDtoList?.map((each, index) => {
        each["serial_no"] =
          parseInt(data?.page?.number) * data?.page?.size + index + 1;
      });
      return data;
    } else [];
  });

//Get All re-enquiry matrix
export const getAllReenquiryReportMatrix = (
  startDate,
  endDate,
  users,
  projectNames,
  projectWise
) =>
  get(
    `report/re-inquiry/matrix?forceFirstAndLastRels=true&paged=true${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${users !== "" ? `&users=${users}` : ``}${
      projectNames !== "" ? `&projects=${projectNames}` : ``
    }${
      projectWise && projectWise !== false
        ? `&projectWise=true`
        : "&projectWise=false"
    }`
  ).then(({ data }) => {
    if (Array.isArray(data)) {
      data?.map((each, index) => {
        each["serial_no"] = index++;
      });
      return data;
    } else [];
  });
//get All Campaign List
export const getAllCampaignList = (
  startDate,
  endDate,
  pageNo,
  pageSize,
  sortCol,
  sortDirection,
  searchText,
  filterSubSource,
  projects
) =>
  get(
    `campaign-setting/campaigns?paged=true${sortCol && `&sort=${sortCol}`},${
      sortDirection && `${sortDirection}`
    }${startDate && `&from=${startDate}`}${endDate && `&to=${endDate}`}${
      filterSubSource && `&subSourceIds=${filterSubSource}`
    }${projects && `&projectIds=${projects}`}${
      searchText && `&search=${searchText}`
    }${pageNo !== "" ? `&page=${pageNo}` : ``}${
      pageSize && `&size=${pageSize}`
    }`
  ).then(({ data }) => data);

// Get Facebook Campaign Insight List
export const getFacebookCampaignInsightsReport = (
  startDate,
  endDate,
  pageNo = 0,
  pageSize = 1000,
  sortCol,
  sortDirection
) =>
  get(
    `report/facebook/insights?paged=true${sortCol && `&sort=${sortCol}`},${
      sortDirection && `${sortDirection}`
    }${startDate && `&from=${startDate}`}${endDate && `&to=${endDate}`}${
      pageNo !== "" ? `&page=${pageNo}` : ``
    }${pageSize && `&size=${pageSize}`}`
  ).then(({ data }) => data);

export const getDCRMFacebookCampaignInsightsReport = (
  startDate,
  endDate,
  pageNo = 0,
  pageSize = 1000,
  sortCol,
  sortDirection
) =>
  get(
    `report/facebook/insights/projects?paged=true${
      sortCol && `&sort=${sortCol}`
    },${sortDirection && `${sortDirection}`}${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${pageNo !== "" ? `&page=${pageNo}` : ``}${
      pageSize && `&size=${pageSize}`
    }`
  ).then(({ data }) => data);

//get facebook insights by id
export const getFacebookInsightsById = (
  objId,
  campaignStructureId,
  nextLevel,
  startDate,
  endDate,
  pageNo = 0,
  pageSize = 1000,
  sortCol,
  sortDirection,
  breakdownId = null,
  publisherPlatformId = null
) =>
  get(
    `report/facebook/insights/${objId}${
      campaignStructureId && `?campaignStructureId=${campaignStructureId}`
    }${`&nextLevel=${nextLevel}`}${
      breakdownId != null ? `&breakdownId=${breakdownId}` : ""
    }${
      publisherPlatformId != null
        ? `&publisherPlatformId=${publisherPlatformId}`
        : ""
    }${startDate && `&from=${startDate}`}${endDate && `&to=${endDate}`}`
  ).then(({ data }) => data);

// get facebook insights all at once
export const getAllFacebookCampaignInsights = (
  startDate,
  endDate,
  pageNo = 0,
  pageSize = 1000,
  sortCol,
  sortDirection,
  projectIds
) =>
  get(
    `report/facebook/insights/v1?paged=true${
      sortCol != null && `&sort=${sortCol}`
    }${sortDirection != null ? `,${sortDirection}` : ""}${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${pageNo !== "" ? `&page=${pageNo}` : ``}${
      pageSize && `&size=${pageSize}`
    }${projectIds && `&projectIds=${projectIds}`}`
  ).then(({ data }) => data);

export const getDCRMFacebookInsightsById = (
  objId,
  campaignStructureId,
  nextLevel,
  startDate,
  endDate,
  pageNo = 0,
  pageSize = 1000,
  sortCol,
  sortDirection,
  breakdownId = null,
  publisherPlatformId = null
) =>
  get(
    `report/facebook/insights/projects/${objId}${
      campaignStructureId && `?campaignLevelId=${campaignStructureId}`
    }${`&nextLevel=${nextLevel}`}${
      breakdownId != null ? `&breakdownId=${breakdownId}` : ""
    }${
      publisherPlatformId != null
        ? `&publisherPlatformId=${publisherPlatformId}`
        : ""
    }${startDate && `&from=${startDate}`}${endDate && `&to=${endDate}`}`
  ).then(({ data }) => data);

// download dcrm facebook insights excel
export const downloadDCRMFacbookInsightsExcel = (
  projectIds,
  fromDate,
  toDate,
  requestOptions
) => {
  return fetch(
    `${baseUrl}/report/facebook/insights/projects/excel?${
      fromDate && `from=${fromDate}`
    }${toDate && `&to=${toDate}`}${
      projectIds != null ? `&projectIds=${projectIds}` : ""
    }`,
    requestOptions
  );
};

// get Ad from adId
export const getFbAdById = fbAdId =>
  get(`report/facebook/insights/ads?fbAdId=${fbAdId}`).then(({ data }) => data);

// Sync Fb Campaigns
export const SyncFbCampaigns = () =>
  post(`facebook/campaigns/sync`).then(({ data }) => data);
// Get FbCampaign List
export const getFbCampaignList = (
  startDate,
  endDate,
  projects,
  searchText,
  pageNo = 0,
  pageSize = 10,
  sortCol,
  sortDirection
) =>
  get(
    `facebook/campaigns?paged=true${
      sortCol != null ? `&sort=${sortCol}` : ""
    },${sortDirection != null ? `${sortDirection}` : ""}${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${
      projects != null ? `&projectIds=${projects}` : ""
    }${searchText != null ? `&search=${searchText}` : ""}${
      pageNo !== "" ? `&page=${pageNo}` : ``
    }${pageSize && `&size=${pageSize}`}`
  ).then(({ data }) => data);

// Get FbCampaign AdSet List
export const getFbCampaignAdSetList = (
  campaignId,
  startDate,
  endDate,
  searchText,
  pageNo = 0,
  pageSize = 10,
  sortCol,
  sortDirection
) =>
  get(
    `facebook/campaigns/${campaignId}/adsets?paged=true${
      sortCol && `&sort=${sortCol}`
    },${sortDirection && `${sortDirection}`}${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${
      searchText != null ? `&search=${searchText}` : ""
    }${pageNo !== "" ? `&page=${pageNo}` : ``}${
      pageSize && `&size=${pageSize}`
    }`
  ).then(({ data }) => data);

// Get FbCampaign AdSet List
export const getFbCampaignAdList = (
  adSetId,
  startDate,
  endDate,
  searchText,
  pageNo = 0,
  pageSize = 10,
  sortCol,
  sortDirection
) =>
  get(
    `facebook/adsets/${adSetId}/ads?paged=true${
      sortCol && `&sort=${sortCol}`
    },${sortDirection && `${sortDirection}`}${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${
      searchText != null ? `&search=${searchText}` : ""
    }${pageNo !== "" ? `&page=${pageNo}` : ``}${
      pageSize && `&size=${pageSize}`
    }`
  ).then(({ data }) => data);

// Add Fb Campaign
export const addFbCampaign = (
  body // Not available anymore
) => post(`fb-campaigns`, body).then(({ data }) => data);

// Add project for Facebook campaign
export const assignProjectToFbCampaign = (fbCampaignId, projectId) =>
  post(
    `facebook/campaigns/projects?${`fbCampaignId=${fbCampaignId}`}${`&projectId=${projectId}`}`
  ).then(({ data }) => data);

// Get Campaign List
export const getFbAdSet = adSetId =>
  get(`facebook/campaigns/adsets/${adSetId}`).then(({ data }) => data);

//Get Ad Set List
export const getFbCampaign = campaignId =>
  get(`facebook/campaigns/${campaignId}`).then(({ data }) => data);

// Update Fb Campaign from meta

export const updateFbCampaign = campaignId => {
  post(`facebook/campaigns/sync/adsets?${`fbCampaignId=${campaignId}`}`).then(
    ({ data }) => data
  );
};

// Get Campaign Expense List
export const getCampaignExpenseList = campaignId =>
  get(`campaign-setting/campaigns/${campaignId}/expenses`).then(
    ({ data }) => data
  );

//change Current Campaign Status
export const changeCampaignStatus = (userId, isActive, body) =>
  put(
    `campaign-setting/campaigns/${userId}/status?isActive=${isActive}`,
    body
  ).then(({ data }) => data);
//Add Campaign
export const addCampaign = body =>
  post(`campaign-setting/campaigns`, body).then(({ data }) => data);
//Edit Campaign
export const editCampaign = (campaignId, endDate) =>
  put(
    `campaign-setting/campaigns/${campaignId}`,
    endDate,
    {},
    {
      "Access-Control-Expose-Headers": "Authorization",
      "Content-Type": "application/json",
    }
  ).then(({ data }) => data);

//Edit Campaign Expense
export const updateCampaignExpense = (campaignId, body) =>
  put(
    `campaign-setting/campaigns/${campaignId}/expenses`,
    body,
    {},
    {
      "Access-Control-Expose-Headers": "Authorization",
      "Content-Type": "application/json",
    }
  ).then(({ data }) => data);

//add Template  settings
export const addTemplate = body =>
  post(`templates`, body).then(({ data }) => data);

//add Template in marketing settings
export const addMarketingTemplate = body =>
  post(`marketing-settings/templates`, body).then(({ data }) => data);

//marked the uploaded doc
export const marketingSettingsDocUploaded = templateId =>
  post(`marketing-settings/templates/${templateId}/uploaded`).then(
    ({ data }) => data
  );
// delete whatsapp template
export const deleteWhatsappTemplate = whatsappTemplateId =>
  del(`marketing-settings/${whatsappTemplateId}`).then(({ data }) => data);

export const deleteAccount = () =>
  del(`users/profile/current-user`).then(({ data }) => data);

//Get User Management Operations
export const getAllUsersList = (
  startDate,
  endDate,
  pageNo,
  pageSize,
  sort = "lastLoginAt",
  sortDir = "desc",
  searchText,
  ActiveFilter,
  teamSelected,
  roleSelected,
  AdditionalRightFilter
) => {
  return get(
    `user-management/users?forceFirstAndLastRels=true&paged=true${
      startDate != null ? `&from=${startDate}` : ``
    }${endDate != null ? `&to=${endDate}` : ``}${
      pageNo !== "" ? `&page=${pageNo}` : ``
    }${pageSize && `&size=${pageSize}`}&sort=${sort},${sortDir}${
      searchText && `&search=${searchText}`
    }${`&isActive=${ActiveFilter}`}${
      teamSelected && `&teamIds=${teamSelected}`
    }${roleSelected && `&roleIds=${roleSelected}`}${
      AdditionalRightFilter
        ? `&hasAdditionalRights=${AdditionalRightFilter}`
        : ""
    }`
  ).then(({ data }) => {
    if (Array.isArray(data?._embedded?.userListDtoList)) {
      data?._embedded?.userListDtoList?.map((each, index) => {
        each["serial_number"] = parseInt(pageNo) * pageSize + index + 1;
      });
      return data;
    } else [];
  });
};

//Add Employee
export const addEmployee = body =>
  post(`user-management/users`, body).then(({ data }) => data);

//get Current User Status
export const changeUserStatus = (userId, isActive, body) =>
  put(`user-management/users/${userId}/status?isActive=${isActive}`, body).then(
    ({ data }) => data
  );

//get All Sub-Org
export const getAllSubOrgUser = () =>
  get(`user-management/users/all-sub-org`).then(({ data }) => data);

//update user details
export const updateEmployeeDetails = (userId, body) =>
  put(`users/profile/${userId}`, body).then(({ data }) => data);

//get user details
export const getEmployeeDetails = userId =>
  get(`users/profile/${userId}`).then(({ data }) => data);

//update user details
export const updateEmployeeDetail = (userId, body) =>
  put(`user-management/users/${userId}`, body).then(({ data }) => data);

//get user details
export const getEmployeeDetail = userId =>
  get(`user-management/users/${userId}`).then(({ data }) => data);

//get all managers
export const getAllManagers = (roleId, userId) =>
  get(
    `user-management/users/${roleId}/managers?${
      userId ? `&userId=${userId}` : ``
    }`
  ).then(({ data }) => data);

//get all roles
export const getAllRoles = () =>
  get(`user-management/roles/users/assignable`).then(({ data }) => data);

//get access rights
export const getAdditionalAccessRights = userId =>
  get(`user-management/users/${userId}/access-rights`).then(({ data }) => data);

//update additional access
export const updateAdditionalAccessRights = (userId, hasRights, body) =>
  put(
    `user-management/users/${userId}/access-rights?hasAdditionalRights=${hasRights}`,
    body
  ).then(({ data }) => data);

//Get Developer List
export const getAllDeveloper = (
  pageNo,
  pageSize,
  sort = "createdOn",
  sortDir = "desc",
  searchText
) => {
  return get(
    `developers?forceFirstAndLastRels=true&paged=true${
      pageNo !== "" ? `&page=${pageNo}` : ``
    }${pageSize && `&size=${pageSize}`}&sort=${sort},${sortDir}${
      searchText && `&search=${searchText}`
    }`
  ).then(({ data }) => {
    if (Array.isArray(data?._embedded?.developerListDtoList)) {
      data?._embedded?.developerListDtoList?.map((each, index) => {
        each["serial_no"] =
          parseInt(data?.page?.number) * data?.page?.size + index + 1;
      });
      return data;
    } else [];
  });
};

//add developer
export const addDeveloper = body =>
  post(`developers`, body).then(({ data }) => data);

//add project
export const addProjectAPI = body =>
  post(`project`, body).then(({ data }) => data);

//add developerOverview
export const commonDeveloperApi = (section, developerId, body) =>
  put(`developers/${developerId}/${section}`, body).then(({ data }) => data);

//get developerOverview
export const commonDeveloperGetApi = developerId =>
  get(`developers${developerId ? `/${developerId}` : ``}`).then(
    ({ data }) => data
  );

//get type provided options
export const getTypes = (type, productType, getIcons) =>
  get(
    `master/options/${type}${productType ? `?productType=${productType}` : ``}${
      getIcons ? `?getIcons=${getIcons}` : ``
    }`
  ).then(({ data }) => {
    return data;
  });
export const getAllProjectAPI = ({ developerId, pageNo, pageSize }) => {
  return get(
    `project/${developerId}/list?forceFirstAndLastRels=true${
      pageNo !== undefined ? `&page=${pageNo}` : ``
    }${pageSize ? `&size=${pageSize}` : ``}&unpaged=true&projectStatus=LIVE`
  ).then(({ data }) => data);
};

export const uploadProjectDoc = (
  projectId,
  contentType = { "Content-Type": "application/json" }
) =>
  post(
    `project/${projectId}/upload-image-url`,
    {},
    {
      "Access-Control-Expose-Headers": "Authorization",
      ...contentType,
    }
  ).then(({ data }) => data);

// project overview put api

export const addProjectDetailAPI = (projectId, body) =>
  put(`project/${projectId}`, body).then(({ data }) => data);

/// project overview get api
export const getProjectDetailsAPI = projectId =>
  get(`project/${projectId}/details`).then(({ data }) => data);

//add directors
export const addDirectors = (developerId, body) =>
  post(`developers/${developerId}/directors`, body).then(({ data }) => data);

//get Directors
export const getDirectors = (developerId, body) =>
  get(`developers/${developerId}/directors`, body).then(({ data }) => data);

//add phase
export const addPhase = (projectId, body) =>
  post(`project/${projectId}/phase`, body).then(({ data }) => data);

//add Contact
export const addContact = (projectId, body) =>
  post(`project/${projectId}/contacts`, body).then(({ data }) => data);

//get Contact
export const getContact = (
  projectId,
  pageNo,
  Rows,
  filterSource,
  filterSubSource,
  searchText
) =>
  get(
    `project/${projectId}/contacts?${
      searchText != "" ? `&search=${searchText}` : ""
    }${pageNo !== undefined ? `&page=${pageNo}` : ""}${
      Rows ? `&size=${Rows}` : ""
    }${filterSource && `&source=${filterSource}`}${
      filterSubSource && `&subSources${filterSubSource}`
    }`
  ).then(({ data }) => data);

export const getAllPhaseAPI = ({ projectId, pageNo, pageSize }) => {
  return get(
    `project/${projectId}/phase/list?forceFirstAndLastRels=true&unpaged=true${
      pageNo !== undefined ? `&page=${pageNo}` : ``
    }${pageSize ? `&size=${pageSize}` : ``}&offset=0`
  ).then(({ data }) => data);
};

export const getAllUnits = ({ projectId, phaseId, pageNumber, pageSize }) => {
  return get(
    `project/${projectId}/phase/${phaseId}/units?forceFirstAndLastRels=true${
      pageNumber !== undefined ? `&page=${pageNumber}` : ""
    }${pageSize ? `&size=${pageSize}` : ""}`
  ).then(({ data }) => data);
};

export const getAllFloors = ({
  projectId,
  phaseId,
  pageNumber = 0,
  pageSize = 100,
}) => {
  return get(
    `project/${projectId}/phase/${phaseId}/floors?forceFirstAndLastRels=true${
      pageNumber !== undefined ? `&page=${pageNumber}` : ""
    }${pageSize ? `&size=${pageSize}` : ""}`
  ).then(({ data }) => data);
};

//get list of cities
export const getCityPassByName = countryName =>
  get(`master/cities?countryName=${countryName}`).then(({ data }) => data);
export const getLocationPassbyName = cityName =>
  get(`master/localities/?cityName=${cityName}`).then(({ data }) => {
    return data;
  });

//get phase list without details
export const getAllPhaseWithoutInfoAPI = projectId => {
  return get(`project/${projectId}/phase/list/no-info`).then(
    ({ data }) => data
  );
};

//toggle phase active/inactive status
export const togglePhaseStatus = (projectId, phaseId) =>
  put(`project/${projectId}/phase/${phaseId}`).then(({ data }) => data);

//add unit for particular phase
export const addUnit = (projectId, phaseId, body) =>
  post(`project/${projectId}/phase/${phaseId}/units`, body).then(
    ({ data }) => data
  );

//add floor unit for particular commercial phase
export const addFloorUnit = (projectId, phaseId, body) =>
  post(`project/${projectId}/phase/${phaseId}/floorUnits`, body).then(
    ({ data }) => data
  );

//add area unit for particular phase
export const addAreaUnit = (projectId, phaseId, body) =>
  post(`project/${projectId}/phase/${phaseId}/areaUnits`, body).then(
    ({ data }) => data
  );

//toggle area unit active/inactive status
export const toggleAreaUnitStatus = (projectId, phaseId, unitId) =>
  put(`project/${projectId}/phase/${phaseId}/floors/${unitId}/isActive`).then(
    ({ data }) => data
  );

// upload for unit
export const uploadUnitFile = (
  projectId,
  phaseId,
  unitId,
  contentType = { "Content-Type": "application/json" }
) =>
  post(
    `project/${projectId}/phase/${phaseId}/units/${unitId}/upload-image-url?isUnitImage=true`,
    {},
    {
      "Access-Control-Expose-Headers": "Authorization",
      ...contentType,
    }
  ).then(({ data }) => data);

// save unit details

export const saveUnitDetailsAPI = (projectId, phaseId, unitId, body) =>
  put(`project/${projectId}/phase/${phaseId}/units/${unitId}`, body).then(
    ({ data }) => data
  );

// update Area unit details

export const updateAreaUnitDetailsAPI = (projectId, phaseId, unitId, body) =>
  put(`project/${projectId}/phase/${phaseId}/floors/${unitId}`, body).then(
    ({ data }) => data
  );

//get api of unit
export const getUnitDetailsAPI = (projectId, phaseId, unitId) =>
  get(`project/${projectId}/phase/${phaseId}/units/${unitId}`).then(
    ({ data }) => data
  );

//get api for project floor
export const getAreaUnitDetailsAPI = (projectId, phaseId, floorId) =>
  get(`project/${projectId}/phase/${phaseId}/floors/${floorId}`).then(
    ({ data }) => data
  );
//get phase details
export const getPhaseDetail = (projectId, phaseId) => {
  return get(`project/${projectId}/phase/${phaseId}/details`).then(
    ({ data }) => data
  );
};

// Upload CSV file
export const unitBulkCSVUpload = (projectId, phaseId, requestOptions) => {
  return fetch(
    `${baseUrl}/project/${projectId}/phase/${phaseId}/unit-csv-upload`,
    requestOptions
  );
};
// download CSV
export const downloanSmapleCSV = (
  projectId,
  phaseId,
  productTypeId,
  requestOptions
) => {
  return fetch(
    `${baseUrl}/project/${projectId}/phase/${phaseId}/units/bulk/sample${
      productTypeId ? `?productTypeId=${productTypeId}` : ``
    }`,
    requestOptions
  );
};

// Get BP dashboard count
export const getBPCount = () =>
  get(`business-partner/dashboard/counts`).then(({ data }) => data);

// consumer contact us enquiry
export const sendConsumerContactUsEnquiry = body => {
  return post(`consumers/contact-us`, body).then(({ data }) => data);
};
// consumer project list for b2c
export const getConsumerPropertiesList = (
  propertyType,
  projectIds,
  isInterested,
  pageNo = 0,
  pageSize,
  sort
) => {
  return get(
    `consumers/projects?forceFirstAndLastRels=true${
      projectIds != undefined ? `&projects=${projectIds}` : ""
    }${propertyType != undefined ? `&propertyType=${propertyType}` : ""}${
      isInterested != undefined ? `&isInterested=${isInterested}` : ""
    }${pageNo !== "" ? `&page=${pageNo}` : ``}${
      pageSize != undefined ? `&size=${pageSize}` : ""
    }${sort != undefined ? `&sort=${sort}` : ""}`
  ).then(({ data }) => data);
};

// get Opportunity Booking Documents for project
export const getConsumerOpportunityBookingDocumentsByProject = projectId =>
  get(`consumers/projects/${projectId}/details`).then(({ data }) => data);

// Create Opportuntiy for Counsumer Interested
export const createConsumerOpportunity = projectId =>
  post(`consumers/opportunities/${projectId}`).then(({ data }) => data);

// //bp lead quality report// //
export const getBpLeadQualityReport = (
  startDate,
  endDate,
  propertyTypeSelected,
  projectNames,
  reportBy,
  endPoint = "activity",
  pageNo,
  pageSize,
  sort,
  sortDir
) => {
  return get(
    `report/bp-lead-bucket/${endPoint}?forceFirstAndLastRels=true${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${
      projectNames && `&projects=${projectNames}`
    }${propertyTypeSelected && `&propertyTypes=${propertyTypeSelected}`}${
      reportBy && reportBy !== undefined ? `&entityCategoryId=${reportBy}` : ""
    }${pageNo !== "" ? `&page=${pageNo}` : ``}${
      pageSize && `&size=${pageSize}`
    }${sort != undefined ? `&sort=${sort},${sortDir}` : `&sort=,`}`
  ).then(({ data }) => data);
};

// developer invitation check
export const developerInvitationCheck = invitationId =>
  post(`developers/${invitationId}`).then(({ data }) => data);

// get account history
export const getAccountHistory = accountId =>
  get(`accounts/${accountId}/history`).then(({ data }) => data);

//lost report
export const getLostReportAPI = ({
  startDate,
  endDate,
  users,
  filterSource,
  filterSubSource,
  projectNames,
  propertyTypeSelected,
  reportBy,
  type = "",
  pageNo,
  Rows,
  sortCol = "statusCounts_RNR",
  sortDirection = "asc",
}) => {
  return get(
    `report/lost-reason/${type ? type : ""}?${
      startDate && `from=${startDate}`
    }${endDate && `&to=${endDate}`}${
      projectNames && `&projects=${projectNames}`
    }${users !== "" ? `&users=${users}` : ``}${
      propertyTypeSelected && `&propertyType=${propertyTypeSelected}`
    }${filterSource && `&sources=${filterSource}`}${
      filterSubSource && `&subSources=${filterSubSource}`
    }${
      reportBy && reportBy !== undefined ? `&entityCategoryId=${reportBy}` : ""
    }${pageNo !== undefined ? `&page=${pageNo}` : ""}${
      Rows ? `&size=${Rows}` : ""
    }${sortCol ? `&sort=${sortCol}` : ""},${sortDirection && `${sortDirection}`}
      `
  ).then(({ data }) => data);
};

// Campiagn Report

export const getCampaignReportSubStatusWise = (
  startDate,
  endDate,
  pageNo,
  pageSize,
  sortCol,
  sortDirection,
  users,
  filterSource,
  filterSubSource,
  projectNames,
  filterQualifyLead,
  filterCampaign,
  filterLostReason,
  includeZero = false
) => {
  return get(
    `report/campaign/sub-status?unpaged=false${
      projectNames && `&projects=${projectNames}`
    }${users !== "" ? `&users=${users}` : ``}${
      filterSource && `&source=${filterSource}`
    }${filterSubSource && `&subSources=${filterSubSource}`}${
      filterQualifyLead && `&qualityLeadStatus=${filterQualifyLead}`
    }${filterCampaign && `&campaigns=${filterCampaign}`}${
      filterLostReason && `&opportunityLostReasons=${filterLostReason}`
    }${`&includeZero=${includeZero}`}${startDate && `&from=${startDate}`}${
      endDate && `&to=${endDate}`
    }${pageNo !== undefined ? `&page=${pageNo}` : ""}${
      pageSize ? `&size=${pageSize}` : ""
    }${sortCol && `&sort=${sortCol}`},${sortDirection && `${sortDirection}`}`
  ).then(({ data }) => {
    if (Array.isArray(data?._embedded?.statusCountList)) {
      data?._embedded?.statusCountList?.map((each, index) => {
        each["serial_no"] =
          parseInt(data?.page?.number) * data?.page?.size + index + 1;
      });
      return data;
    } else [];
  });
};

export const getCampaignReportStatusWise = (
  startDate,
  endDate,
  pageNo,
  pageSize,
  sortCol,
  sortDirection,
  users,
  filterSource,
  filterSubSource,
  projectNames,
  filterQualifyLead,
  filterCampaign,
  filterLostReason,
  includeZero = false
) => {
  return get(
    `report/campaign/status?unpaged=false${
      projectNames && `&projects=${projectNames}`
    }${users !== "" ? `&users=${users}` : ``}${
      filterSource && `&source=${filterSource}`
    }${filterSubSource && `&subSources=${filterSubSource}`}${
      filterQualifyLead && `&qualityLeadStatus=${filterQualifyLead}`
    }${filterCampaign && `&campaigns=${filterCampaign}`}${
      filterLostReason && `&opportunityLostReasons=${filterLostReason}`
    }${`&includeZero=${includeZero}`}${startDate && `&from=${startDate}`}${
      endDate && `&to=${endDate}`
    }${pageNo !== undefined ? `&page=${pageNo}` : ""}${
      pageSize ? `&size=${pageSize}` : ""
    }${sortCol && `&sort=${sortCol}`},${sortDirection && `${sortDirection}`}`
  ).then(({ data }) => {
    if (Array.isArray(data?._embedded?.statusCountList)) {
      data?._embedded?.statusCountList?.map((each, index) => {
        each["serial_no"] =
          parseInt(data?.page?.number) * data?.page?.size + index + 1;
      });
      return data;
    } else [];
  });
};

export const getCampaignReportTotalSubStatusWise = (
  startDate,
  endDate,
  users,
  filterSource,
  filterSubSource,
  projectNames,
  filterQualifyLead,
  filterCampaign,
  filterLostReason,
  includeZero = false
) => {
  return get(
    `report/campaign/graph/sub-status?unpaged=true${
      projectNames && `&projects=${projectNames}`
    }${users !== "" ? `&users=${users}` : ``}${
      filterSource && `&source=${filterSource}`
    }${filterSubSource && `&subSources=${filterSubSource}`}${
      filterQualifyLead && `&qualityLeadStatus=${filterQualifyLead}`
    }${filterCampaign && `&campaigns=${filterCampaign}`}${
      filterLostReason && `&opportunityLostReasons=${filterLostReason}`
    }${`&includeZero=${includeZero}`}${startDate && `&from=${startDate}`}${
      endDate && `&to=${endDate}`
    }`
  ).then(({ data }) => data);
};

export const reassignUserOpportunity = (userId, newUserId) => {
  return post(
    `opportunity/reassign/leads${userId && `?userId=${userId}`}${
      newUserId && `&newOwnerId=${newUserId}`
    }`
  ).then(({ data }) => data);
};

export const reassignUserTeammates = (userId, newmanagerId) => {
  return post(
    `users/${userId}/reassign-manager${
      newmanagerId && `?newManagerId=${newmanagerId}`
    }`
  ).then(({ data }) => data);
};

export const getCampaignReportTotalStatusWise = (
  startDate,
  endDate,
  users,
  filterSource,
  filterSubSource,
  projectNames,
  filterQualifyLead,
  filterCampaign,
  filterLostReason,
  includeZero = false
) => {
  return get(
    `report/campaign/graph/status?unpaged=true${
      projectNames && `&projects=${projectNames}`
    }${users !== "" ? `&users=${users}` : ``}${
      filterSource && `&source=${filterSource}`
    }${filterSubSource && `&subSources=${filterSubSource}`}${
      filterQualifyLead && `&qualityLeadStatus=${filterQualifyLead}`
    }${filterCampaign && `&campaigns=${filterCampaign}`}${
      filterLostReason && `&opportunityLostReasons=${filterLostReason}`
    }${`&includeZero=${includeZero}`}${startDate && `&from=${startDate}`}${
      endDate && `&to=${endDate}`
    }`
  ).then(({ data }) => data);
};

export const getSourcePerformance = ({ startDate, endDate }) =>
  get(
    `opportunity/dashboard/source-performance${
      startDate && `?from=${startDate}`
    }${endDate && `&to=${endDate}`}`
  ).then(({ data }) => data);

//whatsApp incoming message
export const outGoingMessage = (oppId, body) =>
  post(`opportunity/${oppId}/whatsapp-message`, body).then(({ data }) => data);

//whatsApp Mark Read
export const markWhatsAppMessageAsRead = oppId =>
  put(`whatsapp/incoming-message/${oppId}/read`).then(({ data }) => data);

//WhatsApp Conversation
export const whatsAppConversations = ({ oppId, latestPage, page, size }) =>
  get(
    `whatsapp/conversation?forceFirstAndLastRels=true${
      page && `&page=${page}`
    }${size && `&size=${size}`}${oppId && `&opportunityId=${oppId}`}${
      latestPage && `&getLatestPage=${latestPage}`
    }`
  ).then(({ data }) => data);

export const getContentList = docType =>
  get(
    `master/document-types${
      docType != undefined ? `?contentTypeId=${docType}` : ``
    }`
  ).then(({ data }) => data);

export const updateTutorialCompleted = () =>
  post(`users/tutorial-completed`).then(({ data }) => data);

export const getTutorialCompleted = () =>
  get(`users/tutorial-completed`).then(({ data }) => data);

export const contentManagementCommonApi = (
  endpath,
  body,
  method = post,
  headers
) => {
  return method(
    `content-management/${endpath}`,
    typeof body === "object" && body !== null ? body : `${body}`,
    undefined,
    headers && headers
  ).then(({ data }) => data);
};

export const contentManagementDocUploaded = contentId =>
  post(`content-management/${contentId}/uploaded`).then(({ data }) => data);

export const getContentListByProjectId = (
  projectId,
  docType,
  pageNo,
  pageSize
) =>
  get(
    `content-management/contents?forceFirstAndLastRels=true${
      projectId ? `&projects=${projectId}` : ``
    }${docType ? `&documentTypes=${docType}` : ``}${
      pageSize && `&size=${pageSize}`
    }${pageNo !== "" ? `&page=${pageNo}` : ``}`
  ).then(({ data }) => data);

export const getBPMetrics = (startDate, endDate, users, projectId) =>
  get(
    `business-partner/dashboard/insights/metrics${
      startDate && `?from=${startDate}`
    }${endDate && `&to=${endDate}`}${users && `&users=${users}`}${
      projectId && `&projectId=${projectId}`
    }`
  ).then(({ data }) => data);

export const getLeadFlowGraph = (startDate, endDate) =>
  get(
    `business-partner/dashboard/insights/lead-flow-graph${
      startDate && `?from=${startDate}`
    }${endDate && `&to=${endDate}`}`
  ).then(({ data }) => data);

export const getActiveBPGraph = (startDate, endDate) =>
  get(
    `business-partner/dashboard/insights/bp-active-graph${
      startDate && `?from=${startDate}`
    }${endDate && `&to=${endDate}`}`
  ).then(({ data }) => data);

export const getBPTrendingGraph = (startDate, endDate, users, projectId) =>
  get(`business-partner/dashboard/insights/bp-activity-trending-graph${
    startDate && `?from=${startDate}`
  }${
    endDate &&
    `&to=${endDate}${users && `&users=${users}`}${
      projectId && `&projectId=${projectId}`
    }`
  }
`).then(({ data }) => data);

export const getProjectOpportunity = (startDate, endDate, users, projectId) =>
  get(
    `business-partner/dashboard/insights/opportunity-of-projects${
      startDate && `?from=${startDate}`
    }${endDate && `&to=${endDate}`}${users && `&users=${users}`}${
      projectId && `&projectId=${projectId}`
    }`
  ).then(({ data }) => data);

export const getBPActivationGraph = (startDate, endDate) =>
  get(
    `business-partner/dashboard/insights/bp-activation-graph${
      startDate && `?from=${startDate}`
    }${endDate && `&to=${endDate}`}`
  ).then(({ data }) => data);

export const getWalkInToBooking = (
  startDate,
  endDate,
  campaigns,
  projects,
  source,
  subSource,
  filterQualifyLead,
  filterLostReason
) =>
  get(
    `report/campaign/graph/walk-in-to-bookings?${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${campaigns && `&campaigns=${campaigns}`}${
      projects && `&projects=${projects}`
    }${source && `&source=${source}`}${
      subSource && `&subSources=${subSource}`
    }${filterQualifyLead && `&qualityLeadStatus=${filterQualifyLead}`}${
      filterLostReason && `&opportunityLostReasons=${filterLostReason}`
    }`
  ).then(({ data }) => data);

export const getProjectCPList = (
  startDate,
  endDate,
  users,
  projectId,
  pageNo = 0,
  Rows,
  sortCol = "bpName",
  sortDirection = "asc",
  dateFilterType
) =>
  get(
    `business-partner/dashboard/insights/bp-list-for-projects?forceFirstAndLastRels=true&paged=true${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${users && `&users=${users}`}${
      projectId && `&projectId=${projectId}`
    }${`&page=${pageNo}`}${
      Rows && `&size=${Rows}`
    }&sort=${sortCol},${sortDirection}${
      dateFilterType && `&bpDateFilterType=${dateFilterType}`
    }`
  ).then(({ data }) => data);

export const getBucketList = (pageNo, pageSize) =>
  get(
    `lead-buckets?${pageSize && `&size=${pageSize}`}${
      pageNo !== "" ? `&page=${pageNo}` : ``
    }`
  ).then(({ data }) => data);

export const addBucket = body =>
  post(`lead-buckets`, body).then(({ data }) => data);

export const getSubSourceForSource = endpoint =>
  get(`master/sub-sources-for-sources?${endpoint}`).then(({ data }) => data);

export const addoppForLeadBucket = (leadBucketId, rmUserIds) =>
  post(
    `lead-buckets/${leadBucketId}/opportunities?rmUserIds=${rmUserIds}`
  ).then(({ data }) => data);

export const getAllOpp = leadBucketId =>
  get(`lead-bucket/${leadBucketId}/opportunities`).then(({ data }) => data);

export const getProjectExemptList = ({
  propertyType,
  exemptProject,
  iconType = 1,
  isProjectHead,
}) =>
  get(
    `project/list?${propertyType ? `propertyType=${propertyType}` : ""}${
      exemptProject ? `&exemptProjects=${exemptProject}` : ""
    }${iconType >= 0 ? `&iconStyle=${iconType}` : ""}${
      isProjectHead ? `&headProjects=${isProjectHead}` : ""
    }`
  ).then(({ data }) => data);

export const getLeadToBooking = (
  startDate,
  endDate,
  campaigns,
  projects,
  source,
  subSource,
  filterQualifyLead,
  filterLostReason
) =>
  get(
    `report/campaign/graph/lead-to-booking?${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${campaigns && `&campaigns=${campaigns}`}${
      projects && `&projects=${projects}`
    }${source && `&source=${source}`}${
      subSource && `&subSources=${subSource}`
    }${filterQualifyLead && `&qualityLeadStatus=${filterQualifyLead}`}${
      filterLostReason && `&opportunityLostReasons=${filterLostReason}`
    }`
  ).then(({ data }) => data);

export const getLeadToVisit = (
  startDate,
  endDate,
  campaigns,
  projects,
  source,
  subSource,
  filterQualifyLead,
  filterLostReason
) =>
  get(
    `report/campaign/graph/lead-to-visit?${startDate && `&from=${startDate}`}${
      endDate && `&to=${endDate}`
    }${campaigns && `&campaigns=${campaigns}`}${
      projects && `&projects=${projects}`
    }${source && `&source=${source}`}${
      subSource && `&subSources=${subSource}`
    }${filterQualifyLead && `&qualityLeadStatus=${filterQualifyLead}`}${
      filterLostReason && `&opportunityLostReasons=${filterLostReason}`
    }`
  ).then(({ data }) => data);

export const getVisitToBooking = (
  startDate,
  endDate,
  campaigns,
  projects,
  source,
  subSource,
  filterQualifyLead,
  filterLostReason
) =>
  get(
    `report/campaign/graph/visit-to-booking?${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${campaigns && `&campaigns=${campaigns}`}${
      projects && `&projects=${projects}`
    }${source && `&source=${source}`}${
      subSource && `&subSources=${subSource}`
    }${filterQualifyLead && `&qualityLeadStatus=${filterQualifyLead}`}${
      filterLostReason && `&opportunityLostReasons=${filterLostReason}`
    }`
  ).then(({ data }) => data);

export const getQualityLeadReport = (
  startDate,
  endDate,
  campaigns,
  projects,
  source,
  subSource,
  filterQualifyLead,
  filterLostReason
) =>
  get(
    `report/campaign/graph/quality-lead-report?${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${campaigns && `&campaigns=${campaigns}`}${
      projects && `&projects=${projects}`
    }${source && `&source=${source}`}${
      subSource && `&subSources=${subSource}`
    }${filterQualifyLead && `&qualityLeadStatus=${filterQualifyLead}`}${
      filterLostReason && `&opportunityLostReasons=${filterLostReason}`
    }`
  ).then(({ data }) => data);

export const getCostPerLeadReport = (
  startDate,
  endDate,
  campaigns,
  projects,
  source,
  subSource,
  filterQualifyLead,
  filterLostReason
) =>
  get(
    `report/campaign/graph/cost-per-lead-report?${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${campaigns && `&campaigns=${campaigns}`}${
      projects && `&projects=${projects}`
    }${source && `&source=${source}`}${
      subSource && `&subSources=${subSource}`
    }${filterQualifyLead && `&qualityLeadStatus=${filterQualifyLead}`}${
      filterLostReason && `&opportunityLostReasons=${filterLostReason}`
    }`
  ).then(({ data }) => data);

export const getCostPerQualityLeadReport = (
  startDate,
  endDate,
  campaigns,
  projects,
  source,
  subSource,
  filterQualifyLead,
  filterLostReason
) =>
  get(
    `report/campaign/graph/cost-per-qllead-nonqllead?${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${campaigns && `&campaigns=${campaigns}`}${
      projects && `&projects=${projects}`
    }${source && `&source=${source}`}${
      subSource && `&subSources=${subSource}`
    }${filterQualifyLead && `&qualityLeadStatus=${filterQualifyLead}`}${
      filterLostReason && `&opportunityLostReasons=${filterLostReason}`
    }`
  ).then(({ data }) => data);

export const getCampaignReportList = (
  pageNo,
  pageSize,
  sort = "createdOn",
  sortDir = "desc",
  startDate,
  endDate,
  campaigns,
  projects,
  source,
  subSource,
  filterQualifyLead,
  filterLostReason
) =>
  get(
    `report/campaign/campaign-list?forceFirstAndLastRels=true&paged=true${
      pageNo !== "" ? `&page=${pageNo}` : ``
    }${pageSize && `&size=${pageSize}`}&sort=${sort},${sortDir}${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${campaigns && `&campaigns=${campaigns}`}${
      projects && `&projects=${projects}`
    }${source && `&source=${source}`}${
      subSource && `&subSources=${subSource}`
    }${filterQualifyLead && `&qualityLeadStatus=${filterQualifyLead}`}${
      filterLostReason && `&opportunityLostReasons=${filterLostReason}`
    }`
  ).then(({ data }) => data);

export const getCampaignCards = (
  startDate,
  endDate,
  campaigns,
  projects,
  source,
  subSource,
  filterQualifyLead,
  filterLostReason
) =>
  get(
    `report/campaign/metrics?${startDate && `&from=${startDate}`}${
      endDate && `&to=${endDate}`
    }${campaigns && `&campaigns=${campaigns}`}${
      projects && `&projects=${projects}`
    }${source && `&source=${source}`}${
      subSource && `&subSources=${subSource}`
    }${filterQualifyLead && `&qualityLeadStatus=${filterQualifyLead}`}${
      filterLostReason && `&opportunityLostReasons=${filterLostReason}`
    }`
  ).then(({ data }) => data);

export const getTemplateList = (projects, propertyType, messageTemplate) =>
  get(
    `marketing-settings/templates?${projects && `projects=${projects}`}${
      propertyType && `&propertyTypes=${propertyType}`
    }${messageTemplate && `&messageTemplateType=${messageTemplate}`}`
  ).then(({ data }) => data);

export const whatsappTemplateSend = (leadBucketId, whatsAppTemplateId) =>
  post(
    `marketing/lead-buckets/${leadBucketId}/templates/${whatsAppTemplateId}`
  ).then(({ data }) => data);

export const getTemplateSentHistory = (
  pageNo,
  pageSize,
  sort = "createdOn",
  sortDir = "desc",
  startDate,
  endDate
) =>
  get(
    `marketing/whatsapp-blasts/history?forceFirstAndLastRels=true&paged=true${
      pageNo !== "" ? `&page=${pageNo}` : ``
    }${pageSize && `&size=${pageSize}`}&sort=${sort},${sortDir}${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}`
  ).then(({ data }) => {
    if (Array.isArray(data?._embedded?.whatsAppTemplateHistoryListDtoList)) {
      data?._embedded?.whatsAppTemplateHistoryListDtoList?.map(
        (each, index) => {
          each["serial_no"] =
            parseInt(data?.page?.number) * data?.page?.size + index + 1;
        }
      );
      return data;
    } else [];
  });

export const whatsAppBlast = body =>
  post(`marketing-settings/whatsapp-blasts`, body).then(({ data }) => data);

export const getOppBlastList = (pageNo, pageSize) =>
  get(
    `marketing-settings/whatsapp-blasts?${pageSize && `&size=${pageSize}`}${
      pageNo !== "" ? `&page=${pageNo}` : ``
    }`
  ).then(({ data }) => data);

export const sendWhatsAppBlast = (whatsAppBlastId, whatsAppTemplateId) =>
  post(
    `marketing/whatsapp-blasts/${whatsAppBlastId}/templates/${whatsAppTemplateId}`
  ).then(({ data }) => data);

export const submitWhatsappBlast = whatsAppBlastId =>
  post(`marketing-settings/whatsapp-blasts/${whatsAppBlastId}/activate`).then(
    ({ data }) => data
  );

export const creativeUploaded = campaignId =>
  post(`campaign-setting/campaigns/${campaignId}/creatives-uploaded`).then(
    ({ data }) => data
  );

export const historyMatrics = (startDate, endDate) =>
  get(
    `marketing/whatsapp-blasts/history/count?${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}`
  ).then(({ data }) => data);

//get opportunity score details
export const getOpportunityScore = opportunityId =>
  get(`opportunity/${opportunityId}/scores`).then(({ data }) => data);

//add Quality lead in oppDetails
export const addQualityLead = (opportunityId, body) =>
  post(`opportunity/${opportunityId}/quality-lead`, body).then(
    ({ data }) => data
  );
// get Quality lead Details
export const getQualityLeadDetails = opportunityId =>
  get(`opportunity/${opportunityId}/quality-lead`).then(({ data }) => data);

// update Quality Lead in oppDetails
export const updateQualityLead = (opportunityId, qualityLeadId, body) =>
  put(`opportunity/${opportunityId}/quality-lead/${qualityLeadId}`, body).then(
    ({ data }) => data
  );

export const sendPreAssignedTemplate = (opportunityId, whatsAppTemplateId) =>
  post(
    `opportunity/${opportunityId}/whatsapp-template?whatsAppTemplateId=${whatsAppTemplateId}`
  ).then(({ data }) => data);

//get report type
export const getActiveReportType = () =>
  get(`master/report-type`).then(({ data }) => data);

export const bpSendOtp = ph =>
  post(`business-partner/registration-event/send-otp?phoneNumber=${ph}`).then(
    ({ data }) => data
  );
export const bpVerifyOtp = body =>
  post(`business-partner/registration-event/verify-otp`, body).then(
    ({ data }) => data
  );

export const getUnitBookingStatus = () =>
  get(`master/unit-booking-status`).then(({ data }) => data);

export const getUnitBookingStatusForProjectPhase = (projectId, phaseIds) =>
  get(
    `project-inventory/${projectId}/units/unit-booking-status${
      phaseIds ? `?phaseIds=${phaseIds}` : ""
    }`
  ).then(({ data }) => data);

export const getUnitTypeStatusForProjectPhase = (projectId, phaseIds) =>
  get(
    `project-inventory/${projectId}/units/unit-type-status${
      phaseIds ? `?phaseIds=${phaseIds}` : ""
    }`
  ).then(({ data }) => data);

export const getUnitTypeForProjectPhase = (projectId, phaseIds) =>
  get(
    `project-inventory/${projectId}/units/unit-types${
      phaseIds ? `?phaseIds=${phaseIds}` : ""
    }`
  ).then(({ data }) => data);

export const getUnitConfigsForProjectPhase = (projectId, phaseIds) =>
  get(
    `project-inventory/${projectId}/units/unit-configs${
      phaseIds ? `?phaseIds=${phaseIds}` : ""
    }`
  ).then(({ data }) => data);

export const getFloorsForProjectPhase = (projectId, phaseIds) =>
  get(
    `project-inventory/${projectId}/units/floors${
      phaseIds ? `?phaseIds=${phaseIds}` : ""
    }`
  ).then(({ data }) => data);

export const getProjectPhases = projectId =>
  get(`project-inventory/${projectId}/units/phases`).then(({ data }) => data);

export const getUnitTypeStatus = () =>
  get(`master/unit-type-status`).then(({ data }) => data);

export const getDivisionTypes = () =>
  get(`master/division-types`).then(({ data }) => data);

export const getProjectConfig = propertyType =>
  get(`master/project-config?propertyType=${propertyType}`).then(
    ({ data }) => data
  );

export const getUnitBlock = projectId =>
  get(`project/${projectId}/units/blocks`).then(({ data }) => data);

export const getUnitFloor = projectId =>
  get(`project/${projectId}/units/floors`).then(({ data }) => data);

export const getListPhase = projectId =>
  get(`project/${projectId}/phase/list/no-info`).then(({ data }) => data);

export const getUnitInventoryDetails = (projectId, unitId) =>
  get(`project-inventory/${projectId}/units/${unitId}`).then(
    ({ data }) => data
  );

// put api for edit creative
export const editCreativeUpdate = (campaignId, body) =>
  put(`campaign-setting/campaigns/${campaignId}`, body).then(
    ({ data }) => data
  );
//get details of currently logged in user
export const getCurrentUserDetails = () =>
  get(`users/profile/current-user`).then(({ data }) => data);
export const getCurrentUserDetailsv2 = docId =>
  get(`users/profile/user-docs/current-user${docId ? `/${docId}` : ``}`).then(
    ({ data }) => data
  );
// update details of currently logged in user
export const updateCurrentUserDetails = body =>
  put(`users/profile/current-user`, body).then(({ data }) => data);
// get profile phtoto url of current user
export const getCurrentUserDocsContentData = docTypeId =>
  get(
    `users/profile/user-docs/current-user/document-type${
      docTypeId != undefined ? `/${docTypeId}` : ``
    }`
  ).then(({ data }) => data);
// upload current-user profie photo
export const UserDocsUpload = (body, userId) =>
  post(`users/profile/user-docs/${userId}`, body).then(({ data }) => data);
//Indicates that a photo has been uploaded to the PreSigned URL
export const UserDocsUploaded = (docId, body, userId) =>
  post(`users/profile/user-docs/${userId}/${docId}/uploaded`, body).then(
    ({ data }) => data
  );
//get users by userrole id
export const getAllUsersByUserRole = userRole =>
  get(`users/userRole${userRole != undefined ? `/${userRole}` : ``}`).then(
    ({ data }) => data
  );
// upload profie photo by user-id
export const CurrentUserDocsUpload = body =>
  post(`users/profile/user-docs/current-user`, body).then(({ data }) => data);
//Indicates that a photo has been uploaded to the PreSigned URL for user id
export const CurrentUserDocsUploaded = (docId, body) =>
  post(`users/profile/user-docs/current-user/${docId}/uploaded`, body).then(
    ({ data }) => data
  );

//get list of image crop shape
export const getImageCropShapes = () =>
  get(`master/image-crop-shapes`).then(({ data }) => data);
export const getBaseTemplate = docTypeId =>
  get(`master/base-templates?documentTypesIds=${docTypeId}`).then(
    ({ data }) => data
  );

export const addPriorityPass = (oppId, body) =>
  post(`priority-passes/${oppId}/priority-pass`, body).then(({ data }) => data);

export const contentRead = contentId =>
  get(`content-management/content-read?contentId=${contentId}`).then(
    ({ data }) => data
  );

export const opportunityDocRead = docId =>
  get(`opportunity/opportunity-docs/${docId}/read`).then(({ data }) => data);

export const getOpportunityDetailsDocs = (
  contentTypeIds,
  opportunityIds,
  documentTypeIds
) =>
  get(`opportunity/opportunity-docs?
  ${contentTypeIds && `contentTypeIds=${contentTypeIds}`}${
    opportunityIds && `&opportunityIds=${opportunityIds}`
  }${documentTypeIds && `&documentTypeIds=${documentTypeIds}`}`).then(
    ({ data }) => data
  );

export const getBaseTemplateData = (
  oppId,
  ownerId,
  startDate,
  templateId,
  projectId
) =>
  get(
    `priority-passes/${oppId}/priority-pass/base-templates/object-map?currentOwnerId=${ownerId}&priorityPassStartDate=${startDate}&baseTemplateId=${templateId}&projectId=${projectId}`
  ).then(({ data }) => data);

export const getIconMainType = () =>
  get(`master/icon-main-type`).then(({ data }) => data);

export const getIconObjectType = id =>
  get(`master/icon-object-type${id ? `?iconMainTypeId=${id}` : ""}`).then(
    ({ data }) => data
  );

export const getIcons = (developerId, iconObjectTypeId) =>
  get(
    `icons/${iconObjectTypeId}?forceFirstAndLastRels=true&page=0&size=20&developerId=${developerId}`
  ).then(({ data }) => data);

export const getAllIcons = (
  iconIds,
  iconObjectTypeId,
  iconObjectId,
  iconStyleId
) =>
  get(
    `icons?${iconIds != null ? `iconIds=${iconIds}` : ""}${
      iconObjectTypeId != null ? `&iconObjectTypeIds=${iconObjectTypeId}` : ""
    }${iconObjectId != null ? `&iconObjectId=${iconObjectId}` : ""} ${
      iconStyleId != null && iconStyleId >= 0
        ? `&iconStyleId=${iconStyleId}`
        : ""
    }`
  ).then(({ data }) => data);

export const getAllIconsMap = ({
  iconIds,
  iconObjectTypeIds,
  iconObjectId,
  iconStyleId,
}) =>
  get(
    `icons/map?iconObjectTypeIds=${iconObjectTypeIds}${
      iconIds ? `&iconIds=${iconIds}` : ""
    }${iconObjectId ? `&iconObjectId=${iconObjectId}` : ""}${
      iconStyleId ? `&iconStyleId=${iconStyleId}` : ""
    }`
  ).then(({ data }) => data);

// update icon isActive status
export const updateIconIsActive = (iconId, isActive) =>
  put(`icons/${iconId}?isActive=${isActive}`).then(({ data }) => data);

export const checkIconExists = (iconObjTypeId, iconObjectId) =>
  get(
    `icons/exists?iconObjectTypeId=${iconObjTypeId}&iconObjectId=${iconObjectId}`
  ).then(({ data }) => data);

export const uploadIcon = body =>
  post(`icons/upload-url`, body).then(({ data }) => data);

export const leadBucketData = (startDate, endDate, projectId) =>
  get(
    `opportunity/dashboard/business-partner/sub-status/project-wise?projectIds=${projectId}${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}`
  ).then(({ data }) => data);

export const getKpiData = (startDate, endDate, projectId) =>
  get(
    `opportunity/dashboard/business-partner/kpi-data?projectIds=${projectId}${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}`
  ).then(({ data }) => data);

export const getBookingData = () =>
  get(`opportunity/dashboard/business-partner/counts`).then(({ data }) => data);

export const updateBPProfile = body =>
  put(`users/profile/current-user`, body).then(({ data }) => data);

export const totalSalesAmount = () =>
  get(
    `opportunity/dashboard/business-partner/counts/vdCount/totalSalesAmount`
  ).then(({ data }) => data);

export const getActiveInactiveRMList = isActive =>
  get(`users/${isActive}`).then(({ data }) => data);

export const getBPGrading = () =>
  get(`master/bp-grade`).then(({ data }) => data);

export const getBPOwnerDetails = bpId =>
  get(`business-partner/owners${bpId ? `/${bpId}` : ""}`).then(
    ({ data }) => data
  );

export const addBPOwnerDetails = (bpId, body) =>
  post(`business-partner/owners${bpId ? `/${bpId}` : ""}`, body).then(
    ({ data }) => data
  );

export const updateBPOwnerDetails = (bpId, body) =>
  put(`business-partner/owners${bpId ? `/${bpId}` : ""}`, body).then(
    ({ data }) => data
  );

export const getActiveRm = (searchText, projectId, onlyProjectHeads) =>
  get(
    `master/rm?${
      searchText != undefined && searchText != null && searchText
        ? `search=${searchText}&`
        : ""
    }${projectId != undefined ? `projects=${projectId}&` : ""}${
      onlyProjectHeads != undefined
        ? `onlyProjectHeads=${onlyProjectHeads}`
        : ""
    }`
  ).then(({ data }) => data);

// get current bp user details
export const getBPProfileDetails = () =>
  get(`users/profile/current-user/bp`).then(({ data }) => data);
//get current consumer user details
export const getConsumerProfileDetails = () =>
  get(`users/profile/current-user/consumer`).then(({ data }) => data);

export const getIconStyles = () =>
  get(`master/icon-styles`).then(({ data }) => data);

//get new dashboard table data
export const getNewOppDashboard = (
  rmId,
  projectId,
  startDate,
  endDate,
  duration,
  tagId,
  teamId
) =>
  get(
    `opportunity/dashboard/count?${rmId?.length > 0 ? `&rmIds=${rmId}` : ``}${
      projectId.length > 0 ? `&projectIds=${projectId}` : ``
    }${startDate && `&from=${startDate}`}${endDate && `&to=${endDate}`}${
      duration &&
      `&duration=${duration}${teamId && `&teamIds=${teamId}`}${
        tagId && `&tag=${tagId}`
      }`
    }`
  ).then(({ data }) => data);

//get new dashboard table data
export const getNewOppDashboardV1 = (
  rmId,
  projectId,
  startDate,
  endDate,
  duration,
  teamId
) =>
  get(
    `opportunity/dashboard/count?${rmId?.length > 0 ? `&rmIds=${rmId}` : ``}${
      projectId.length > 0 ? `&projectIds=${projectId}` : ``
    }${startDate && `&from=${startDate}`}${endDate && `&to=${endDate}`}${
      duration && `&timePeriodId=${duration}`
    }${teamId && `&teamIds=${teamId}`}`
  ).then(({ data }) => data);

//get bp list for dashboard
export const getDashboradBPList = (bpStatus, searchText, pageNo, pageSize) =>
  get(
    `opportunity/dashboard/business-partners?forceFirstAndLastRels=true&paged=true${
      pageSize && `&size=${pageSize}`
    }${pageNo !== "" ? `&page=${pageNo}` : ``}${
      searchText && `&search=${searchText}`
    }${bpStatus && `&bpStatus=${bpStatus}`}`
  ).then(({ data }) => data);

export const getBPStatus = () =>
  get(`master/bp-status`).then(({ data }) => data);

export const getDashboardBPStatus = () =>
  get(`master/bp-status/dashboard`).then(({ data }) => data);

export const getCPCount = (
  projectId,
  startDate,
  endDate,
  duration,
  bpmSelected,
  smSelected,
  isProjectHead
) =>
  get(
    `opportunity/dashboard/bp/counts?${projectId && `projectIds=${projectId}`}${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${duration && `&duration=${duration}`}${
      bpmSelected && `&businessPartnerManagerIds=${bpmSelected}`
    }${smSelected && `&salesManagerIds=${smSelected}`} ${
      isProjectHead ? `&headProjects=${isProjectHead}` : ""
    }`
  ).then(({ data }) => data);

export const getBPTotalCount = () =>
  get(`opportunity/dashboard/bp/total-counts`).then(({ data }) => data);

export const getBPPermormanceCount = (
  startDate,
  endDate,
  duration,
  status,
  bpIds,
  lead,
  projectIds,
  bpmSelected,
  smSelected,
  searchText,
  isProjectHead,
  page,
  size,
  sort,
  sortDir
) =>
  get(
    `opportunity/dashboard/bp/performance-counts?forceFirstAndLastRels=true&paged=true${
      page != undefined ? `&page=${page}` : ""
    }${
      size ? `&size=${size}` : ""
    }&sort=${sort},${sortDir}${`&userIds=${bpIds}`}${
      startDate && `&from=${startDate}`
    }${`&statusId=${status}`}${endDate && `&to=${endDate}`}${
      duration && `&timePeriodId=${duration}`
    }${`&allLeads=${lead}`}${
      projectIds.length > 0 ? `&projectIds=${projectIds}` : ``
    }${bpmSelected && `&businessPartnerManagerIds=${bpmSelected}`}${
      smSelected && `&salesManagerIds=${smSelected}`
    }${searchText && `&bpSearch=${searchText}`}${
      isProjectHead ? `&headProjects=${isProjectHead}` : ""
    }`
  ).then(({ data }) => data);

export const getBPPermormanceCountProjectWise = (
  startDate,
  endDate,
  duration,
  status,
  bpIds,
  lead,
  projectIds,
  bpmSelected,
  smSelected,
  searchText,
  isProjectHead,
  includeZero = false,
  page,
  size,
  sort,
  sortDir
) =>
  get(
    `opportunity/dashboard/bp/performance-counts/projects?forceFirstAndLastRels=true&paged=true${
      page != undefined ? `&page=${page}` : ""
    }${
      size ? `&size=${size}` : ""
    }&sort=${sort},${sortDir}${`&userIds=${bpIds}`}${
      startDate && `&from=${startDate}`
    }${`&statusId=${status}`}${endDate && `&to=${endDate}`}${
      duration && `&duration=${duration}`
    }${`&allLeads=${lead}`}${
      projectIds.length > 0 ? `&projectIds=${projectIds}` : ``
    }${bpmSelected && `&businessPartnerManagerIds=${bpmSelected}`}${
      smSelected && `&salesManagerIds=${smSelected}`
    }${searchText && `&bpSearch=${searchText}`}${
      isProjectHead ? `&headProjects=${isProjectHead}` : ""
    }${`&includeZero=${includeZero}`}`
  ).then(({ data }) => data);

//get binary data of BP Performance
export const getBinaryBPPerformanceData = (
  startDate,
  endDate,
  status,
  bpIds,
  lead,
  projectIds,
  bpmSelected,
  smSelected,
  searchText,
  requestOptions
) => {
  return fetch(
    `${baseUrl}/opportunity/dashboard/bp/performance-counts/projects/excel?${`userIds=${bpIds}`}${
      startDate && `&from=${startDate}`
    }${`&statusId=${status}`}${
      endDate && `&to=${endDate}`
    }${`&allLeads=${lead}`}${
      projectIds.length > 0 ? `&projectIds=${projectIds}` : ``
    }${bpmSelected && `&businessPartnerManagerIds=${bpmSelected}`}${
      smSelected && `&salesManagerIds=${smSelected}`
    }${searchText && `&bpSearch=${searchText}`}`,
    requestOptions
  );
};

export const getCurrentBPList = isProjectHead =>
  get(
    `business-partner/current-user-list/dashboard${
      isProjectHead ? `?headProjects=${isProjectHead}` : ""
    }`
  ).then(({ data }) => data);

export const getSalesManagerRmDashboardPerformanceCount = (
  startDate,
  endDate,
  searchText,
  pageNo,
  Rows,
  smSelected,
  includeZero = false
) =>
  get(
    `opportunity/dashboard/sm/performance-counts?forceFirstAndLastRels=true&paged=true${
      pageNo != undefined ? `&page=${pageNo}` : ""
    }${Rows ? `&size=${Rows}` : ""}${startDate && `&from=${startDate}`}${
      endDate && `&to=${endDate}`
    }${
      smSelected && `&salesManagerIds=${smSelected}`
    }${`&includeZero=${includeZero}`}${searchText && `&smSearch=${searchText}`}`
  ).then(({ data }) => data);

//bp acticity reltaed api
export const getBPActivityList = () =>
  get(`master/bp-activities`).then(({ data }) => data);

export const getBPActivityLog = bpId =>
  get(`activity/business-partner/${bpId}`).then(({ data }) => data);

export const addBPActivity = (bpId, body) =>
  post(`activity/business-partner/${bpId}`, body).then(({ data }) => data);

export const bpActivityDocUploaded = (bpId, docId) =>
  post(`activity/business-partner/${bpId}/${docId}/uploaded`).then(
    ({ data }) => data
  );

export const rmReassignmentList = (bpId, bpUserId, isSalesManager) =>
  get(
    `business-partner/reassign?${bpId != undefined ? `bpId=${bpId}` : ""}${
      bpUserId != undefined ? `bpUserId=${bpUserId}` : ""
    }&isSalesManager=${isSalesManager}`
  ).then(({ data }) => data);

export const addsmDetails = body =>
  post(`user-managers`, body).then(({ data }) => data);

export const bpManagerReaasignment = (
  bpId,
  bpUserId,
  rmId,
  projectId,
  isSalesManager
) =>
  put(
    `business-partner/reassign?${bpId != undefined ? `bpId=${bpId}` : ""}${
      bpUserId != undefined ? `bpUserId=${bpUserId}` : ""
    }${
      rmId != undefined ? `&newRmId=${rmId}` : ""
    }&isSalesManager=${isSalesManager}&projectId=${projectId}`
  ).then(({ data }) => data);

export const getLeadExpirationData = () =>
  get(`lead-expiry-buckets`).then(({ data }) => data);

export const addLeadExpirationDetails = body =>
  post(`lead-expiry-buckets`, body).then(({ data }) => data);

export const getDateFilterTypes = () =>
  get(`master/date-filter-types`).then(({ data }) => data);

export const getDateFilterTypeList = id =>
  get(`master/date-filters?dateFilterTypeId=${id}`).then(({ data }) => data);
export const getUserTeammates = filterId =>
  get(
    `master-closed/user-teams${filterId ? `?filterTypeId=${filterId}` : ""}`
  ).then(({ data }) => data);

export const getProductType = (
  projectTypeId,
  projectOptionTypeId,
  projectChargeTypeId
) =>
  get(
    `master/project-options?${
      projectTypeId != null ? `productTypeId=${projectTypeId}` : ``
    }${
      projectOptionTypeId != null
        ? `&projectOptionTypeIds=${projectOptionTypeId}`
        : ``
    }${
      projectChargeTypeId != null
        ? `&projectChargeTypeIds=${projectChargeTypeId}`
        : ``
    }`
  ).then(({ data }) => data);

export const addBulkUnit = (projectId, phaseId, requestOptions) => {
  return fetch(
    `${baseUrl}/project/${projectId}/phase/${phaseId}/units/bulk`,
    requestOptions
  );
};

export const getSubOrgs = id =>
  get(`user-management/sub-orgs?${id ? `userId=${id}` : ""}`).then(
    ({ data }) => data
  );

export const currentUserManager = () =>
  get(`users/manager`).then(({ data }) => data);

export const addCostSheet = (projectId, productTypeId, body) =>
  post(
    `projects/${projectId}/product-types/${productTypeId}/cost-sheets/admin`,
    body
  ).then(({ data }) => data);

export const getCostSheetCharges = (projectId, productTypeId) =>
  get(
    `projects/${projectId}/product-types/${productTypeId}/cost-sheets/admin`
  ).then(({ data }) => data);

export const getProjectChargesTypes = () =>
  get(`master/project-charge-types`).then(({ data }) => data);

export const projectOptionTypes = () =>
  get(`master/project-option-types`).then(({ data }) => data);

export const chargeValueType = () =>
  get(`master/charge-value-types`).then(({ data }) => data);

export const productTypes = projectId =>
  get(`project/${projectId}/product-types`).then(({ data }) => data);

export const getPlanTypes = () =>
  get(`master/plan-types`).then(({ data }) => data);

export const getChargesTypes = () =>
  get(`master/charge-types`).then(({ data }) => data);

export const getFincityOfferingTypes = () =>
  get(`master/fincity-offerings`).then(({ data }) => data);

export const getBookingAmountTypes = () =>
  get(`master/amount-types`).then(({ data }) => data);

export const getAllMaritalStatus = () =>
  get(`master/marital-status`).then(({ data }) => data);

export const getAllRelationTypes = () =>
  get(`master/relation-types`).then(({ data }) => data);

export const getAllGenderTypes = () =>
  get(`master/gender-types`).then(({ data }) => data);

export const getAllTemplateTypes = () =>
  get(`master/template-types`).then(({ data }) => data);

export const addOrUpdateCostSheet = (projectId, phaseId, unitId, body) =>
  post(
    `projects/${projectId}/phases/${phaseId}/units/${unitId}/cost-sheets`,
    body
  ).then(({ data }) => data);

export const addPdfForCostSheet = (projectId, phaseId, unitId, body) =>
  post(
    `projects/${projectId}/phases/${phaseId}/units/${unitId}/cost-sheets/pdf`,
    body
  ).then(({ data }) => data);

export const getCostSheetDetails = (
  projectId,
  phaseId,
  unitId,
  oppId,
  chargeTypeId
) =>
  get(
    `projects/${projectId}/phases/${phaseId}/units/${unitId}/cost-sheets?opportunityId=${oppId}&chargeTypeId=${chargeTypeId}`
  ).then(({ data }) => data);

export const getProjectUnitDetails = (projectId, phaseId, unitId, oppId) =>
  get(
    `projects/${projectId}/phases/${phaseId}/units/${unitId}?opportunityId=${oppId}`
  ).then(({ data }) => data);

export const getInventoryUnitChart = (
  projectId,
  unitTypeStatus,
  unitBookingStatus,
  unitTypes,
  unitConfigIds,
  phaseId,
  floorNumbers,
  entireFloor
) =>
  get(
    `project-inventory/${projectId}/units/floor-wise?${unitBookingStatus}${unitTypeStatus}${unitTypes}${unitConfigIds}${phaseId}${floorNumbers}${
      entireFloor ? `entireFloors=${entireFloor}` : ``
    }`
  ).then(({ data }) => data);

export const getTowerTypes = projectId =>
  get(`project/${projectId}/units/towers`).then(({ data }) => data);

export const getInventoryList = (
  projectId,
  phase,
  bookingType,
  status,
  unitType,
  floor,
  config,
  page,
  size
) =>
  get(
    `project-inventory/${projectId}/units?forceFirstAndLastRels=true&paged=true${
      page != undefined ? `&page=${page}` : ""
    }${size ? `&size=${size}` : ""}${
      status.length > 0 ? `&unitTypeStatusIds=${status}` : ""
    }${bookingType.length > 0 ? `&unitBookingStatusIds=${bookingType}` : ""}${
      config.length > 0 ? `&configIds=${config}` : ""
    }${phase.length > 0 ? `&phaseIds=${phase}` : ""}${
      unitType.length > 0 ? `&unitTypes=${unitType}` : ""
    }${floor}`
  ).then(({ data }) => data);

export const getPhaseList = projectId =>
  get(`project/${projectId}/units/phases`).then(({ data }) => data);

export const getUnitTypes = projectId =>
  get(`project/${projectId}/units/unit-types`).then(({ data }) => data);

export const getQuotesDetails = (projectId, unitId) =>
  get(`project-inventory/${projectId}/units/${unitId}`).then(
    ({ data }) => data
  );

export const getProjectUnitConfig = projectId =>
  get(`project/${projectId}/units/unit-configs`).then(({ data }) => data);

//Get all info related to ifscCode
export const getIFSCCodeInfo = ifscCode =>
  get(`master/ifsc/${ifscCode}`).then(({ data }) => data);

//Get All Active Banks which are in system
export const getAllBanks = searchText =>
  get(`master/banks?search=${searchText != undefined ? searchText : ""}`).then(
    ({ data }) => data
  );

//Get All Active Applicant types which are in system
export const getAllApplicantTypes = () =>
  get(`master/applicant-types`).then(({ data }) => data);

//Get All Active Profession types which are in system
export const getProfessionTypes = () =>
  get(`master/profession-types`).then(({ data }) => data);

//Get All Active Industries types which are in system
export const getAllIndustriesTypes = () =>
  get(`master/industries`).then(({ data }) => data);

//Get All Active Industries Functions types which are in system
export const getAllIndustryFunctionsTypes = () =>
  get(`master/industry-functions`).then(({ data }) => data);

//Get All Active Incomes types which are in system
export const getAllIncomesTypes = () =>
  get(`master/incomes`).then(({ data }) => data);

//Get All Active Payments types which are in system
export const getAllPaymentsTypes = () =>
  get(`master/payment-types`).then(({ data }) => data);

//Get Content Information for Booking form
export const getBookingFormContentInfo = bookingFormId =>
  get(`opportunities/${bookingFormId}/content`).then(({ data }) => data);

//Add or Update Content Information for Booking form
export const addBookingFormContentInfo = (bookingFormId, body) =>
  post(`opportunities/${bookingFormId}/content`, body).then(({ data }) => data);

//Get General Information for Booking form
export const getBookingFormGeneralInfo = (oppId, unitId) =>
  get(`opportunities/${oppId}/units/${unitId}/general`).then(
    ({ data }) => data
  );

//Add or Update General Information for Booking form
export const addBookingFormGeneralInfo = (oppId, unitId, body) =>
  post(`opportunities/${oppId}/units/${unitId}/general`, body).then(
    ({ data }) => data
  );

//Get Personal Information for Booking form
export const getBookingFormPersonalInfo = bookingFormId =>
  get(`opportunities/${bookingFormId}/personal`).then(({ data }) => data);

//Add or Update Personal Information for Booking form
export const addBookingFormPersonalInfo = (bookingFormId, body) =>
  post(`opportunities/${bookingFormId}/personal`, body).then(
    ({ data }) => data
  );
//Get Professional Information for Booking form
export const getBookingFormProfessionalDetails = bookingFormId =>
  get(`opportunities/${bookingFormId}/professional`).then(({ data }) => data);

//Add or Update Professional Information for Booking form
export const addBookingFormProfessionalDetails = (bookingFormId, body) =>
  post(`opportunities/${bookingFormId}/professional`, body).then(
    ({ data }) => data
  );

//Get Payment Details for Booking form
export const getBookingFormPaymentDetails = bookingFormId =>
  get(`opportunities/${bookingFormId}/payment`).then(({ data }) => data);

//Get Payment Details for Booking form
export const getCommercialBookingFormPaymentDetails = bookingFormId =>
  get(`opportunities/commercial/${bookingFormId}/payment`).then(
    ({ data }) => data
  );

//Add or Update Payment Details for Booking form
export const addBookingFormPaymentDetails = (bookingFormId, body) =>
  post(`opportunities/${bookingFormId}/payment`, body).then(({ data }) => data);

export const addCommercialBookingFormPaymentDetails = (bookingFormId, body) =>
  post(`opportunities/commercial/${bookingFormId}/payment`, body).then(
    ({ data }) => data
  );

//Get acknowledgment  for Booking form
export const getBookingFormAcknowledgment = bookingFormId =>
  get(`opportunities/${bookingFormId}/acknowledgment`).then(({ data }) => data);

//Add or Update Payment for Booking form
export const addBookingFormAcknowledgment = bookingFormId =>
  post(`opportunities/${bookingFormId}/acknowledgment`).then(
    ({ data }) => data
  );

//Complete Booking form
export const markBookingFormCompleted = (bookingFormId, body) =>
  post(`opportunities/${bookingFormId}/completed`, body).then(
    ({ data }) => data
  );

export const getOppsByProject = (projectId, searchText) =>
  get(`projects/${projectId}/opportunities?search=${searchText}`).then(
    ({ data }) => data
  );

export const getOpportunityDetailsContent = (
  contentTypeIds,
  opportunityIds,
  documentTypeIds
) =>
  get(`content-management/contents/details?
  ${contentTypeIds && `contentTypeIds=${contentTypeIds}`}${
    opportunityIds && `&opportunityIds=${opportunityIds}`
  }${documentTypeIds && `&documentTypeIds=${documentTypeIds}`}`).then(
    ({ data }) => data
  );

// upload Opportunity contents apis
export const addOpportunityDocs = (body, contentName, opportunityId) =>
  post(`opportunity/opportunity-docs/${opportunityId}/upload`, body).then(
    ({ data }) => data
  );

//Indicates that a opportunity document has been uploaded to the PreSigned URL
export const OpportunityDocsUploaded = docId =>
  post(`opportunity/opportunity-docs/${docId}/uploaded`).then(
    ({ data }) => data
  );

export const getALLBPTeammateList = (
  startDate,
  endDate,
  pageNo,
  size,
  searchText
) =>
  get(
    `business-partner/dashboard/teammates?forceFirstAndLastRels=true&paged=true${
      pageNo != undefined && `&page=${pageNo}`
    }${size && `&size=${size}`}${startDate ? `&from=${startDate}` : ""}${
      endDate ? `&to=${endDate}` : ""
    }${searchText ? `&search=${searchText}` : ""}`
  ).then(({ data }) => data);

export const inventoryTableView = (
  projectId,
  unitTypeStatus,
  unitConfigIds,
  phaseId,
  floorNumbers
) =>
  get(
    `project-inventory/${projectId}/units/table-wise?${unitTypeStatus}${unitConfigIds}${phaseId}${floorNumbers}`
  ).then(({ data }) => data);

export const getRmBpPerformanceStatus = () =>
  get(`master/status/bp-performance-count`).then(({ data }) => data);

export const doStatusChange = (bpId, isActive = true) =>
  put(`business-partner/${bpId}/isActive?isActive=${isActive}`).then(
    ({ data }) => data
  );

export const marketingReport = (
  sort,
  sortDir,
  startDate,
  endDate,
  dateFilterType,
  campaigns,
  projects,
  propertyType,
  subSource,
  pageNo,
  pageSize
) =>
  get(
    `report/marketing-report?forceFirstAndLastRels=true&paged=true${`&page=${pageNo}`}${
      pageSize && `&size=${pageSize}`
    }&sort=${sort},${sortDir}${startDate && `&from=${startDate}`}${
      endDate && `&to=${endDate}`
    }${dateFilterType >= 0 ? `&dateFilterType=${dateFilterType}` : ``}${
      campaigns && `&campaigns=${campaigns}`
    }${projects && `&projects=${projects}`}${
      subSource && `&subSources=${subSource}`
    }${propertyType && `&propertyTypes=${propertyType}`}`
  ).then(({ data }) => data);

export const getAllTemplatesForTemplateType = (templateType, projectId) =>
  get(
    `templates/${templateType}${projectId ? `?projectId=${projectId}` : ``}`
  ).then(({ data }) => data);

export const addBookingFormTemplates = (projectId, body) =>
  post(`${projectId}/booking-form-templates`, body).then(({ data }) => data);

export const getTemplateDetailsById = (templateType, templateId) =>
  get(`templates/${templateType}/${templateId}`).then(({ data }) => data);

export const getDefaultTemplateDetails = (templateType, projectId) =>
  get(
    `templates/${templateType}/default${
      projectId ? `?projectId=${projectId}` : ``
    }`
  ).then(({ data }) => data);

export const checkTemplateExist = (templateType, projectId) =>
  get(
    `templates/${templateType}/default/exists${
      projectId ? `?projectId=${projectId}` : ``
    }`
  ).then(({ data }) => data);

export const getSystemMessageType = () =>
  get(`master/system-message-types`).then(({ data }) => data);

export const getSystemMessageEntityType = () =>
  get(`master/system-message-entity-types`).then(({ data }) => data);

export const getWalkInFormTypes = () =>
  get("master/walkin-form-types").then(({ data }) => data);

export const getBPGraphData = (bpIds, from, to) =>
  get(
    `opportunity/dashboard/bp/graph-counts?bpIds=${bpIds}&from=${from}&to=${to}`
  ).then(({ data }) => data);

export const saveReleaseNotes = body =>
  post(`announcements`, body).then(({ data }) => data);

export const getAllAnnouncements = (
  announcementTypeIds,
  projectIds,
  pageSize,
  pageNo
) =>
  get(`announcements?forceFirstAndLastRels=true&paged=true${
    pageSize && `&size=${pageSize}`
  }${
    pageNo !== "" ? `&page=${pageNo}` : ``
  }${`&announcementTypeIds=${announcementTypeIds}`}${
    projectIds != null ? `&projectIds=${projectIds}` : ""
  }
    `).then(({ data }) => data);

export const readAnnouncement = announcementId =>
  post(`announcements/read?announcementId=${announcementId}`);

export const getUnreadAnnouncementsCount = () =>
  get(`announcements/unread-counts`);

export const loginThroughToken = body =>
  post(`v1/users/login/token`, body).then(({ data }) => data);

export const getLocationFromId = id =>
  get(`location-addresses/${id}`).then(({ data }) => data);

export const getUserDocsByUserId = userId =>
  get(`users/profile/user-docs/${userId}`).then(({ data }) => data);

export const getUserDocsByUserIdAndDocId = (userId, docId) =>
  get(`users/profile/user-docs/${userId}/${docId}`).then(({ data }) => data);

export const getResidentialProjectMetaData = projectId =>
  get(`project/${projectId}/metadata`).then(({ data }) => data);
export const getActiveCallProviders = () =>
  get(`master/call-providers`).then(({ data }) => data);

export const getMasterRights = (roleId, additionalRights) =>
  get(
    `master-closed/access-rights?${roleId ? `roleId=${roleId}` : ``}${
      additionalRights ? `&additionalRights=${additionalRights}` : ``
    }`
  ).then(({ data }) => data);

export const getUserDocsByUserIdAndDocTypeId = (userId, docTypeId) =>
  get(`users/profile/user-docs/${userId}/document-type/${docTypeId}`).then(
    ({ data }) => data
  );

export const getContentType = () =>
  get(`master/content-types`).then(({ data }) => data);

export const getCurrentCallProvider = () =>
  get(`v1/calls/users/provider`).then(({ data }) => data);

export const callConnect = oppId =>
  post(`v1/calls/opportunities/${oppId}`).then(({ data }) => data);

export const oppGeoLocation = (
  pageNo,
  pageSize,
  sort,
  sortDir = "opportunity",
  propertyType,
  source,
  status,
  project,
  searchText,
  rm,
  subSource,
  startDate,
  endDate
) =>
  get(
    `report/opportunity-geo?forceFirstAndLastRels=true${`&page=${pageNo}`}${
      pageSize && `&size=${pageSize}`
    }&sort=${sort},${sortDir}${startDate && `&from=${startDate}`}${
      endDate && `&to=${endDate}`
    }${propertyType != undefined ? `&propertyType=${propertyType}` : ``}${
      source?.length > 0 ? `&sourceIds=${source}` : ``
    }${status != undefined ? `&statusIds=${status}` : ``}${
      project?.length > 0 ? `&projectIds=${project}` : ``
    }${searchText?.length > 0 ? `&search=${searchText}` : ``}${
      rm?.length > 0 ? `&rmUserIds=${rm}` : ``
    }${subSource?.length > 0 ? `&subSourceIds=${subSource}` : ``}`
  ).then(({ data }) => {
    if (Array.isArray(data?._embedded?.opportunityGeoReportDtoList)) {
      data?._embedded?.opportunityGeoReportDtoList?.map((each, index) => {
        each["serial_number"] = parseInt(pageNo) * pageSize + index + 1;
      });
      return data;
    } else [];
  });

export const geoLocationStatusCount = (
  status,
  startDate,
  endDate,
  propertyType,
  source,
  project,
  postalCode,
  rm,
  subSource
) =>
  get(
    `report/opportunity-geo/status-count?${startDate && `&from=${startDate}`}${
      endDate && `&to=${endDate}`
    }${propertyType != undefined ? `&propertyType=${propertyType}` : ``}${
      source?.length > 0 ? `&sourceIds=${source}` : ``
    }${status != undefined ? `&statusIds=${status}` : ``}${
      project?.length > 0 ? `&projectIds=${project}` : ``
    }${postalCode?.length > 0 ? `&postalCode=${postalCode}` : ``}${
      rm?.length > 0 ? `&rmUserIds=${rm}` : ``
    }${subSource?.length > 0 ? `&subSourceIds=${subSource}` : ``}`
  ).then(({ data }) => data);

export const getAllLeadConflictBucket = (
  pageNo,
  pageSize,
  projectIds,
  isActive
) =>
  get(
    `lead-conflict-buckets?forceFirstAndLastRels=true&paged=true${
      pageSize && `&size=${pageSize}`
    }${pageNo !== "" ? `&page=${pageNo}` : ``}${
      projectIds != undefined ? `&projectIds=${projectIds}` : ""
    }${isActive != undefined ? `&isActive=${isActive}` : ""}`
  ).then(({ data }) => data);

export const getLeadConflictBucket = leadConflictBucketId =>
  get(`lead-conflict-buckets/${leadConflictBucketId}`).then(({ data }) => data);

export const addLeadConflictBucket = body =>
  post(`lead-conflict-buckets`, body).then(({ data }) => data);

export const updateLeadConflictBucket = (bucketId, body) =>
  put(`lead-conflict-buckets/${bucketId}`, body).then(({ data }) => data);

export const getLeadConflictBucketRules = leadConflictBucketId =>
  get(`lead-conflict-buckets/${leadConflictBucketId}/rules`).then(
    ({ data }) => data
  );
export const setLeadConflictBucketRules = (leadConflictBucketId, body) =>
  post(`lead-conflict-buckets/${leadConflictBucketId}/rules`, body).then(
    ({ data }) => data
  );

export const setLeadConflictBucketActiveStatus = (
  leadConflictBucketId,
  isActive
) =>
  put(
    `lead-conflict-buckets/${leadConflictBucketId}/is-active?isActive=${isActive}`
  ).then(({ data }) => data);

export const setLeadConflictBucketRuleActiveStatus = (
  leadConflictBucketId,
  sourceId,
  subSourceId,
  leadConflictRuleStatus
) =>
  put(
    `lead-conflict-buckets/${leadConflictBucketId}/rules/status?sourceId=${sourceId}${
      subSourceId != null ? `&subSourceId=${subSourceId}` : ""
    }&leadConflictRuleStatus=${leadConflictRuleStatus}`
  ).then(({ data }) => data);

export const deleteLeadConflictBucketRules = (
  leadConflictBucketId,
  sourceId,
  subSourceId
) =>
  put(
    `lead-conflict-buckets/${leadConflictBucketId}/rules/is-Active?sourceId=${sourceId}${
      subSourceId != null ? `&subSourceId=${subSourceId}` : ""
    }`
  ).then(({ data }) => data);

export const addProjectScript = (projectId, body) =>
  post(`project/${projectId}/call-script`, body).then(({ data }) => data);

export const canCheckIn = (lat, lng, workFromHome) =>
  get(
    `attendances/can-check-in?${lat ? `lat=${lat}` : ``}${
      lng ? `&lng=${lng}` : ``
    }${`&workFromHome=${workFromHome}`}`
  ).then(({ data }) => data);

export const canUserTakeLeave = body =>
  get(
    `attendances/can-take-leave?${`leaveTypeId=${body?.leaveTypeId}`}${`&endDate=${body?.endDate}`}${`&startDate=${body?.startDate}`}${`&comment=""`}`
  ).then(({ data }) => data);

export const getUserLeaveTypes = () =>
  get(`master/check-in-types`).then(({ data }) => data);

export const getUserAttendanceTypes = () =>
  get(`master/attendance-types`).then(({ data }) => data);

export const canCheckOut = (lat, lng, workFromHome) =>
  get(
    `attendances/can-check-out?${lat && `lat=${lat}`}${
      lng && `&lng=${lng}`
    }${`&workFromHome=${workFromHome}`}`
  ).then(({ data }) => data);

export const userCheckInCheckOut = (locationOfficeId, body) =>
  post(
    `attendances?${
      locationOfficeId ? `locationOfficeId=${locationOfficeId}` : ""
    }`,
    body
  ).then(({ data }) => data);

export const getAllUsersAttendanceLog = (
  pageNo,
  pageSize,
  startDate,
  teamIds,
  search,
  isActive,
  sortCol,
  sortDirection
) =>
  get(
    `attendances/users?forceFirstAndLastRels=true${`&page=${pageNo}`}${
      pageSize != undefined && `&size=${pageSize}`
    }${sortCol ? `&sort=${sortCol}` : ""}${
      sortDirection !== undefined && sortDirection != ""
        ? `,${sortDirection}`
        : ``
    }${startDate && `&from=${startDate}`}${`&isActive=${isActive}`}${
      teamIds != null && teamIds?.length > 0 ? `&teamIds=${teamIds}` : ``
    }${search?.length > 0 ? `&search=${search}` : ``}`
  ).then(({ data }) => data);

export const getUserAttendanceLog = (
  userId,
  pageNo,
  pageSize,
  startDate,
  endDate,
  leaveTypeIds,
  attendanceTypeIds
) =>
  get(
    `attendances/users/${userId}?forceFirstAndLastRels=true${
      pageNo != undefined && `&page=${pageNo}`
    }${pageSize != undefined && `&size=${pageSize}`}${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${
      leaveTypeIds?.length > 0 ? `&leaveTypeIds=${leaveTypeIds}` : ``
    }${
      attendanceTypeIds?.length > 0
        ? `&attendanceTypeIds=${attendanceTypeIds}`
        : ``
    }`
  ).then(({ data }) => data);

export const getUserAttendanceCounts = (userId, startDate, endDate) =>
  get(
    `attendances/users/${userId}/counts${startDate && `?from=${startDate}`}${
      endDate && `&to=${endDate}`
    }`
  ).then(({ data }) => data);

export const getAttendanceLog = (startDate, endDate) =>
  get(
    `attendances?${startDate ? `from=${startDate}` : ""}${
      endDate ? `&to=${endDate}` : ""
    }`
  ).then(({ data }) => data);

export const saveBpOfficeLocation = (businessPartnerId, body) =>
  post(
    `location-addresses/offices/bp?${
      businessPartnerId && `businessPartnerId=${businessPartnerId}`
    }`,
    body
  ).then(({ data }) => data);

export const getProjectScript = projectId =>
  get(`project/${projectId}/call-script`).then(({ data }) => data);

export const getDeveloperTeammate = (
  searchText,
  developerId,
  pageNo,
  pageSize
) =>
  get(`developers/${developerId}/teammates?forceFirstAndLastRels=true${
    pageSize && `&size=${pageSize}`
  }${pageNo !== "" ? `&page=${pageNo}` : ``}${
    searchText?.length > 0 ? `&search=${searchText}` : ``
  }
  `).then(({ data }) => data);

export const addDeveloperTeammate = (developerId, body) =>
  post(`developers/${developerId}/teammates`, body).then(({ data }) => data);

export const inviteDeveloper = developerId =>
  post(`developers/${developerId}/invite`).then(({ data }) => data);

export const outGoingPersonalMessage = oppId =>
  post(`opportunity/${oppId}/whatsapp-message/personal`).then(
    ({ data }) => data
  );

export const outGoingContentMessage = (oppId, contentId) =>
  post(`opportunity/${oppId}/whatsapp-message/${contentId}`).then(
    ({ data }) => data
  );

export const getWhatsAppMessageType = () =>
  get(`master/whatsapp-message-type`).then(({ data }) => data);

export const addProjectColors = (projectId, body) =>
  post(`project/${projectId}/colors`, body).then(({ data }) => data);

export const getSingleProjectColor = projectId =>
  get(`project/${projectId}/colors`).then(({ data }) => data);

export const getProjectsColors = projectIds =>
  get(
    `project/colors${projectIds?.length > 0 ? `?projectIds=${projectIds}` : ""}`
  ).then(({ data }) => data);

export const getLocationsLatLong = addressId =>
  get(`location-addresses/${addressId}/geometry`).then(({ data }) => data);

export const getProjectActive = (isActive, projectId) =>
  put(`project/${projectId}/${isActive}`).then(({ data }) => data);

export const onBoardingComplete = () =>
  post(`business-partner/onboard/complete`).then(({ data }) => data);

export const bpOnboardDocRead = docTypeId =>
  get(`business-partner/onboard/doc-read?docTypeId=${docTypeId}`).then(
    ({ data }) => data
  );

export const getHoardingCallReport = (
  startDate,
  endDate,
  filterSource,
  filterSubSource,
  propertyTypeSelected,
  projectNames,
  endPoint = "activity",
  pageNo,
  pageSize,
  sortCol,
  sortDirection
) => {
  return get(
    `report/project-phones/${endPoint}?forceFirstAndLastRels=true&paged=true${
      startDate && `&from=${startDate}`
    }${endDate && `&to=${endDate}`}${
      projectNames && `&projectIds=${projectNames}`
    }${filterSource && `&sourceIds=${filterSource}`}${
      filterSubSource && `&subSourceIds=${filterSubSource}`
    }${propertyTypeSelected && `&propertyType=${propertyTypeSelected}`}${
      pageNo !== undefined ? `&page=${pageNo}` : ""
    }${pageSize ? `&size=${pageSize}` : ""}${
      sortCol ? `&sort=${sortCol}` : ""
    }${sortDirection !== undefined ? `,${sortDirection}` : ``}`
  ).then(({ data }) => data);
};
