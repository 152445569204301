import React, { useState } from "react";
import { updatePasswordAPI, updatePasswordAPIv1 } from "../../constants/api";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { setToken as loginTokenSetter } from "../../../common/utils/auth-utils";
import { logout } from "../../../common/utils/auth-utils";
import CommonInput from "../CommonComponent/CommonInput";
import Eye from "../../../images/icons/eye (1).svg";
import CloseEye from "../../../images/icons/slashEye.svg";
import Toast from "../../../common/components/Toast/Toast";

const validationSchema = Yup.object().shape({
  curr_Password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(15, "Password must not be longer than 15 characters")
    .required("Password is required"),

  new_Password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(15, "Password must not be longer than 15 characters")
    .required("Password is required")
    .matches(/[!@#$%^&*(),.?":{}|<>]/g, "Must contain an Special Charecter")
    .matches(/(?=.*[a-z])/g, "Must contain an lower case Charecter")
    .matches(/(?=.*[A-Z])/g, "Must contain an Upper case Charecter")
    .matches(/(?=.*\d)/g, "Must contain atleast one number"),

  confirmPassword: Yup.string()
    .required("Password is required")
    .oneOf([Yup.ref("new_Password"), null], "Password Not Matched"),
});

const UpdatePassword = props => {
  const [errMsg, setErrMsg] = useState("");
  const [new_password_icon, setNewPasswordIcon] = useState(false);
  const [confirm_icon, setConfirmIcon] = useState();
  const [current_pass_show, setCurrentPassShow] = useState();
  const [currnt_password_type = "password", setCurrntPasswordType] = useState();
  const [new_password_type = "password", setNewPasswordType] = useState();
  const [confirm_password = "password", setConfirmPasswordType] = useState();
  const [deviceId, setDeviceId] = useState(localStorage.getItem("deviceId"));

  return (
    <Formik
      initialValues={{
        curr_Password: "",
        new_Password: "",
        confirmPassword: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        //login api call
        updatePasswordAPIv1({
          // password: values?.curr_Password,
          changePasswordDto: {
            oldPassword: values?.curr_Password,
            newPassword: values?.confirmPassword,
          },
          deviceType: deviceId,
        })
          .then(res => {
            logout();
            Toast("info", "Your password has been changed!", 3000, "top-right");
          })
          .catch(err => {
            setErrMsg(err?.response?.data?.message);
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <div
          className="w-full"
          /*onLoad={() =>
            props.activeDropDownLink != 4
              ? props.setActiveDropDownLink(4)
              : null
          }*/
        >
          <h1 className="text-center py-4 font-khand text-2xl font-semibold">
            Change Password
          </h1>

          <Form
            autoComplete="off"
            // className=" pb-2 mt-5  max-w-lg mx-auto w-full  text-left items-center bg-white "
            onSubmit={handleSubmit}
          >
            <div className="pt-16 m-2 mx-4 xs:mx-auto md:mx-4 lg:mx-auto basic_container_box bg-white border border-navy-400 rounded-lg pb-10 px-5 max-w-lg ">
              <div className="block w-10/12 mb-10 mx-auto md:flex md:w-full   items-center ">
                <div className=" mb-8 md:mb-0 w-full flex  items-center ">
                  <div className="w-full">
                    <CommonInput
                      name="curr_Password"
                      type={currnt_password_type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.curr_Password}
                      stylesContainer={{
                        height: "52px",
                        maxWidth: "100%",
                      }}
                      legendName="Current Password"
                      error={touched?.curr_Password && errors?.curr_Password}
                      errorMsg={errors?.curr_Password}
                      touched={touched?.curr_Password}
                    />
                  </div>
                  <img
                    className="items-center z-10 h-8 w-7 -ml-10 "
                    onClick={() => {
                      current_pass_show
                        ? setCurrntPasswordType("password")
                        : setCurrntPasswordType("text");
                      setCurrentPassShow(!current_pass_show);
                    }}
                    id="toggle"
                    src={current_pass_show ? CloseEye : Eye}
                    alt="showPassword"
                  />
                </div>
              </div>

              <div className="block w-10/12  mb-10 mx-auto md:flex md:w-full mt-8 md:mt-5 items-center ">
                <div className="mt-3 mb-8 md:mt-4 md:mb-0 w-full flex  items-center ">
                  <div className="w-full">
                    <CommonInput
                      name="new_Password"
                      type={new_password_type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.new_Password}
                      stylesContainer={{
                        height: "52px",
                        maxWidth: "100%",
                      }}
                      legendName="New Password"
                      error={touched?.new_Password && errors?.new_Password}
                      errorMsg={errors?.new_Password}
                      touched={touched?.new_Password}
                    />
                  </div>
                  <img
                    className="items-center z-10 h-8 w-7 -ml-10 "
                    onClick={() => {
                      new_password_icon
                        ? setNewPasswordType("password")
                        : setNewPasswordType("text");
                      setNewPasswordIcon(!new_password_icon);
                    }}
                    id="toggle"
                    src={new_password_icon ? CloseEye : Eye}
                    alt="showPassword"
                  />
                </div>
              </div>
              <div className="block  mb-10 w-10/12 mx-auto md:flex md:w-full mt-8 md:mt-5 items-center ">
                <div className="mt-3 mb-8 md:mt-4 md:mb-0 w-full flex  items-center  ">
                  <div className="w-full">
                    <CommonInput
                      name="confirmPassword"
                      type={confirm_password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.confirmPassword}
                      stylesContainer={{
                        height: "52px",
                        maxWidth: "100%",
                      }}
                      legendName="Confirm Password"
                      error={
                        touched?.confirmPassword && errors?.confirmPassword
                      }
                      errorMsg={errors?.confirmPassword}
                      touched={touched?.confirmPassword}
                    />
                  </div>
                  <img
                    className="items-center z-10 h-8 w-7 -ml-10 "
                    onClick={() => {
                      confirm_icon
                        ? setConfirmPasswordType("password")
                        : setConfirmPasswordType("text");
                      setConfirmIcon(!confirm_icon);
                    }}
                    id="toggle"
                    src={confirm_icon ? CloseEye : Eye}
                    alt="showPassword"
                  />
                </div>
              </div>
            </div>
            <div className="w-full  mt-6">
              <button
                id="submit"
                className="submitButton  uppercase  w-56 lg:w-80  "
                type="submit"
                disabled={
                  errors?.new_Password ||
                  errors?.confirmPassword ||
                  values?.new_Password != values?.confirmPassword ||
                  values?.new_Password == "" ||
                  values?.confirmPassword == "" ||
                  errors?.curr_Password ||
                  errors?.curr_Password == ""
                }
              >
                SUBMIT
              </button>
              <div className="text-red-200 mt-3 text-center">{errMsg}</div>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default UpdatePassword;
