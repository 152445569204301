import axios from "axios";
import lodashGet from "lodash/get";
import { networkErrorMessages } from "../constants/messages";
//import { baseUrl } from '../../../data/SiteConfig';
import { setAuthorizationHeader, getUserType } from "./cookie-utils";
import { baseUrl } from "gatsby-env-variables";
import LogOut from "../components/Logout/Logout";
import Toast from "../components/Toast/Toast";

const axiosConfig = {
  baseURL: baseUrl,
  timeout: 3600 * 5000,
};
let isNetworkErrorMsgShow = false;
export const LemsAPI = axios.create(axiosConfig);
/**
 * @description These pieces allow us to throw errors on connection timeouts
 * @see https://github.com/axios/axios/issues/647#issuecomment-459517694
 * @returns {{ abort: { token: string, cancel: function }, connectionTimeout: setTimeout }}
 */
const getRequestAbortionPieces = () => {
  const abort = axios.CancelToken.source();
  const connectionTimeout = setTimeout(
    () => abort.cancel(`Connection timeout of ${axiosConfig.timeout}ms.`),
    axiosConfig.timeout
  );

  return { abort, connectionTimeout };
};

/**
 * @param {string} path
 * @param {{token: string}} options
 * @param {?Object.<string, any>} parameters URL parameters to include in the query string
 * @returns {Promise<AxiosPromise<any>>}
 */
export const get = async (
  path,
  { token, parameters, headers } = {},
  axiosClient = LemsAPI
) => {
  const { abort, connectionTimeout } = getRequestAbortionPieces();

  return axiosClient
    .get(`/${path}`, {
      headers: { ...headers, ...setAuthorizationHeader(token) },
      cancelToken: abort.token,
      params: parameters,
    })
    .then(response => {
      clearTimeout(connectionTimeout);
      return response;
    });
};

export const del = async (
  path,
  { token, parameters, headers } = {},
  axiosClient = LemsAPI
) => {
  const { abort, connectionTimeout } = getRequestAbortionPieces();

  return axiosClient
    .delete(`/${path}`, {
      headers: { ...headers, ...setAuthorizationHeader(token) },
      cancelToken: abort.token,
      params: parameters,
    })
    .then(response => {
      clearTimeout(connectionTimeout);
      return response;
    });
};

/**
 * @param {string} path
 * @param {?Object.<string, any>} body
 * @param {{token: string}} options
 * @param {?Object.<string, any>} headers
 * @returns {Promise<AxiosResponse<any>>}
 */
export const post = async (path, body, { token } = {}, headers) => {
  const { abort, connectionTimeout } = getRequestAbortionPieces();
  return LemsAPI.post(`/${path}`, body, {
    headers: { ...headers, ...setAuthorizationHeader(token) },
    cancelToken: abort.token,
  }).then(response => {
    clearTimeout(connectionTimeout);
    return response;
  });
};

/**
 * @param {string} path
 * @param {?Object.<string, any>} body
 * @param {{token: string}} options
 * @param {?Object.<string, any>} headers
 * @returns {Promise<AxiosResponse<any>>}
 */
export const put = async (path, body, { token } = {}, headers) => {
  const { abort, connectionTimeout } = getRequestAbortionPieces();
  return LemsAPI.put(`/${path}`, body, {
    headers: { ...headers, ...setAuthorizationHeader(token) },
    cancelToken: abort.token,
  }).then(response => {
    clearTimeout(connectionTimeout);
    return response;
  });
};

/**
 * @param {string} path
 * @param {?Object.<string, any>} body
 * @param {{token: string}} options
 * @returns {Promise<AxiosResponse<any>>}
 */
export const patch = async (path, body, { token } = {}) => {
  const { abort, connectionTimeout } = getRequestAbortionPieces();

  return LemsAPI.patch(`/${path}`, body, {
    headers: setAuthorizationHeader(token),
    cancelToken: abort.token,
  }).then(response => {
    clearTimeout(connectionTimeout);
    return response;
  });
};

/**
 * @description Take an expected server error object and return its error. If object is unexpected,
 * assume the server is down and return a relavant error message.
 *
 * @export
 * @param {Error?} errorObject
 * @returns {string} A user-facing error message
 */
export const getServerErrorMessage = errorObject => {
  // _.get's third argument is the default message
  // if errorObject.response.data.error doesn't resolve, it means that the server is down
  const errorMessage = lodashGet(
    errorObject,
    "response.data.error",
    networkErrorMessages.serverDown
  );

  return errorMessage;
};
LemsAPI.defaults.headers = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
};
/*LemsAPI.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.params = { ...config.params, timestamp: Date.now() };
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);*/
// Add a response interceptor
//const instance = axios.create();
LemsAPI.interceptors.response.use(
  response => {
    try {
      if (!isAliveWebSocket()) {
        let webSocket = new webSocketConnection();
        webSocket.connect(socketData({ socketInstance: webSocket }));
      }
    } catch (e) {}
    return response;
  },
  error => {
    if ((error.message = "Network Error" && !error.response)) {
      if (!isNetworkErrorMsgShow) {
        Toast(
          "error",
          "Network Error - You are offline",
          "3000",
          "bottom-right"
        );
        isNetworkErrorMsgShow = true;
        setTimeout(() => {
          isNetworkErrorMsgShow = false;
        }, 3200);
      }
    } else {
      const { status, data, config } = error.response;
      if (status == 401 && data.path !== "/users/login") {
        LogOut({
          hardReload: getUserType() == 3 ? true : false,
          routeTo:
            getUserType() == 0
              ? "/"
              : getUserType() == 1
              ? "/bp"
              : getUserType() == 3
              ? "/?&user=consumer"
              : "",
        });
      }
      return Promise.reject(error);
    }
  }
);
