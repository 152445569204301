import { createContext } from "react";

export const DeveloperDataContext = createContext({
  appData: {
    developerDetails: {},
    activePages: {},
    campaignDetails: {},
    appliedGlobalFilter: {},
  },
  setAppData: () => {},
});
