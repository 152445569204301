export const setFiltersInfo = filtersInfo => {
  localStorage.setItem("filtersInfo", JSON.stringify(filtersInfo));
  return true;
};

export const getFiltersInfo = () => {
  return localStorage.hasOwnProperty("filtersInfo")
    ? JSON.parse(localStorage.getItem("filtersInfo"))
    : false;
};

export const removeFiltersInfo = key => {
  if (localStorage.filtersInfo) {
    var obj = JSON.parse(localStorage.filtersInfo);
    var delKey = key;
    if (obj[delKey]) {
      delete obj[delKey];
    }
    localStorage.filtersInfo = JSON.stringify(obj);
  }
  return;
};
