import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    color: '#4ba5af',

    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}));

export default function CircularIndeterminate() {
  const classes = useStyles();

  return (
    <div
      className="flex mx-auto content-center items-center justify-center "
      style={{
        top: '40%',
        color: '#4ba5af'
      }}
    >
      <div className={classes.root}>
        <CircularProgress />
      </div>
    </div>
  );
}
