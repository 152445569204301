import React from "react";
import "./PopUp.scss";
import { navigate } from "gatsby";
import Modal from "../Modal/modal";
import toast from "../../../common/components/Toast/Toast";
import { FailureSvg, SuccessDes, LoadingDes } from "./CommonSvgs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

const PopUp = props => {
  switch (props.type) {
    case "default":
    default:
      return success(
        props.text,
        props.type,
        props.dontClose,
        props.autoClose,
        props.show,
        props.setShow,
        props.goto,
        props.gotoLink,
        props.gotoText,
        props.component
      );
    case "success":
      return success(
        props.text,
        props.type,
        props.dontClose,
        props.autoClose,
        props.show,
        props.setShow,
        props.goto,
        props.gotoLink,
        props.gotoText,
        props.component
      );
    case "fail":
      return fail(
        props.text,
        props.type,
        props.dontClose,
        props.autoClose,
        props.show,
        props.setShow,
        props.goto,
        props.gotoLink,
        props.gotoText,
        props.component
      );
    case "loading":
      return loading(
        props.text,
        props.iconType,
        props.type,
        props.dontClose,
        props.autoClose,
        props.show,
        props.setShow,
        props.goto,
        props.gotoLink,
        props.gotoText,
        props.component
      );
  }
};

const ModalWithAnim = (
  text,
  icon,
  type,
  dontClose = false,
  autoClose = false,
  show = false,
  setShow,
  goto = false,
  gotoLink,
  gotoText = "Dismiss",
  component = ""
) => {
  return (
    <React.Fragment>
      <Modal
        show={show}
        type={type}
        setShow={setShow}
        noCloseBtn={false}
        dontClose={dontClose}
        autoClose={autoClose}
      >
        <div className="flex flex-col justfy-center items-center content-center text-center text-black-500">
          {icon}
          <div className="w-full mt-2 mb-2">{text}</div>
          {component && <div className="w-full mt-2 mb-4">{component}</div>}
          {goto ? (
            <div className="w-full mt-1 mb-2">
              <button
                className="submitButton w-full"
                style={{ marginTop: "0px" }}
                onClick={() => {
                  gotoLink ? navigate(gotoLink) : setShow(false);
                }}
              >
                {gotoText}
              </button>
            </div>
          ) : null}
        </div>
      </Modal>
    </React.Fragment>
  );
};

const success = (
  text,
  type,
  dontClose,
  autoClose,
  show,
  setShow,
  goto = true,
  gotLink,
  gotText
) => {
  let successDes = <SuccessDes />;

  return ModalWithAnim(
    text,
    successDes,
    type,
    dontClose,
    autoClose,
    show,
    setShow,
    goto,
    gotLink,
    gotText
  );
};
const fail = (
  text,
  type,
  dontClose,
  autoClose,
  show,
  setShow,
  goto = true,
  gotLink,
  gotText
) => {
  let failureDes = (
    <FontAwesomeIcon
      icon={faExclamationTriangle}
      style={{ color: "#ec5e66", width: "70px", height: "70px" }}
    />
  );

  return ModalWithAnim(
    text,
    failureDes,
    type,
    dontClose,
    autoClose,
    show,
    setShow,
    goto,
    gotLink,
    gotText
  );
};
const loading = (
  text,
  iconType,
  type,
  dontClose,
  autoClose,
  show,
  setShow,
  goto,
  gotLink,
  gotText
) => {
  let loadingDes;
  if (iconType == "circle") {
    loadingDes = (
      <div className="loader">
        <div className="loader__figure"></div>
      </div>
    );
  } else {
    loadingDes = <LoadingDes />;
  }
  return ModalWithAnim(
    text,
    loadingDes,
    type,
    dontClose,
    autoClose,
    show,
    setShow,
    goto,
    gotLink,
    gotText
  );
};
export default React.memo(PopUp);
