import React from "react"
import { toast, Slide } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./Toast.css"
import {
  faCheckCircle,
  faInfoCircle,
  faTimesCircle,
  faDoNotEnter
} from '@fortawesome/pro-solid-svg-icons';

toast.configure()
const Toast = (type, text, time, position) => {
  switch (type) {
    case "default":
    default:
      return basic(text, time, position)
    case "success":
      return success(text, time, position)
    case "error":
      return error(text, time, position)
    case "info":
      return info(text, time, position)
    case "warning":
      return warn(text, time, position)
  }
}
const toastWithIcon = (text, icon) => {
  return (
    <React.Fragment>
      <span className="px-2">
        <FontAwesomeIcon icon={icon} />
      </span>
      <span>{text}</span>
    </React.Fragment>
  )
}
const success = (text, time, position) => {
  toast.success(toastWithIcon(text, faCheckCircle), {
    position: position,
    transition: Slide,
    autoClose: time,
  })
}
const error = (text, time, position) => {
  toast.error(toastWithIcon(text, faTimesCircle), {
    position: position,
    transition: Slide,
    autoClose: time,
  })
}
const warn = (text, time, position) => {
  toast.warn(toastWithIcon(text, faDoNotEnter), {
    position: position,
    transition: Slide,
    autoClose: time,
  })
}
const info = (text, time, position) => {
  toast.info(toastWithIcon(text, faInfoCircle), {
    position: position,
    transition: Slide,
    autoClose: time,
  })
}
const basic = (text, time, position) => {
  toast(text, {
    position: position,
    transition: Slide,
    autoClose: time,
  })
}
export default Toast
