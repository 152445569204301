import cookie from "js-cookie";
import jwt_decode from "jwt-decode";
const isBrowser = typeof window !== `undefined`;

export const setAuthCookies = ({ id, token, username, userType }) => {
  cookie.set("token", token);
  cookie.set("id", id);
  cookie.set("userNumber", username);
  cookie.set("userType", userType);
};
export const setUserName = userName => {
  cookie.set("userName", userName);
};
export const setUserType = userType => {
  cookie.set("userType", userType);
};
export const setRoleCookies = role => {
  cookie.set("role", role);
};
export const setTeamCookies = team => {
  cookie.set("team", team);
};

export const setLoginTypeCookies = type => {
  cookie.set("loginType", type);
};

export const setProjectHeadCookies = isProjectHead => {
  cookie.set("isProjectHead", isProjectHead);
};

export const setIsApprovedCookie = isApproved => {
  cookie.set("isApproved", isApproved);
}

export const setAskForPasswordCookie = (askForPassword) => {
  cookie.set("askForPassword", askForPassword)
}

export const getLoginTypeCookies = () => {
  return cookie.get("loginType");
};

export const removeLoginTypeCookies = () => {
  cookie.remove("loginType");
};

export const removeAuthCookies = () => {
  cookie.remove("token");
  cookie.remove("id");
  cookie.remove("userNumber");
  cookie.remove("userType");
};
export const getUserType = () => {
  return cookie.get("userType");
};
export const setAuthorizationHeader = (token = getAuthToken()) => {
  return { Authorization: `Bearer ${token}` };
};
export const getUserNumber = () => {
  return cookie.get("userNumber");
};
export const getUserName = () => {
  return cookie.get("userName");
};
export const getUserId = () => {
  return cookie.get("id");
};
export const getAuthToken = () => {
  return cookie.get("token");
};
export const getRole = () => {
  return cookie.get("role");
};
export const getTeam = () => {
  return cookie.get("team");
};

export const getProjectHead = () => {
  return cookie.get("isProjectHead");
};

export const getIsApproved = () => {
  return cookie.get("isApproved") != undefined ? JSON.parse(cookie.get("isApproved")) : "";
}

export const getAskForPassword = () => {
  return cookie.get("askForPassword") != undefined ? JSON.parse(cookie.get("askForPassword")) : "";
}

export const isLoggedIn = () => {
  if (!isBrowser) return false;
  return cookie.get("token") !== undefined;
};
export const hasValidAuthToken = (token = cookie.get("token")) => {
  if (token === undefined) {
    return false;
  }
  const jwt = jwt_decode(token);
  const currentTime = new Date().getTime() / 1000;

  return currentTime < jwt.exp;
};
