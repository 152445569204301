import React, { useState } from "react";
import classnames from "classnames";
import NewSpinner from "../../../common/components/NewSpinner/NewSpinner";
import "./CommonInput.css";
import { Search } from "@material-ui/icons";
import styles from "../../../containers/Settings/ProjectManagement/CostSheet/InputView.module.css";

function CommonInput({
  name,
  max,
  min,
  maxLength,
  id,
  type = "text",
  legendName,
  placeholder,
  value,
  onChange,
  onBlur,
  onKeyUp,
  onKeyDown,
  onKeyPress,
  disabled,
  error,
  errorMsg,
  touched,
  onClickSearch,
  stylesContainer = {
    height: "52px",
    maxWidth: "100%",
  },
  legendStyle = {},
  inputStyle = {},
  onBlurStyle = {},
  fieldsetStyle = {},
  newFieldSetStyle = false,
  classNameProp = "",
  showLoading,
  isRmFilter,
  searchIconPosition = "left",
  searchIconStyle,
  LoadingComponent = NewSpinner,
  showLegend = true,
}) {
  const [isFocussed, setIsFocussed] = useState(false);
  const inputRef = React.useRef(null);
  const legendRef = React.useRef(null);
  var { otherInput } = styles;

  const commonInputProps = {
    name,
    max,
    min,
    type,
    id,
    value,
    onChange,
    onKeyUp,
    onKeyDown,
    onKeyPress,
    maxLength,
    placeholder,
    onFocus: () => {
      setIsFocussed(true);
      if (showLegend) {
        legendRef.current.style.position = "relative";
        legendRef.current.style.transition = "all .3s";
        legendRef.current.style.fontSize = "12px";
        legendRef.current.style.paddingLeft = "0px";
      }
    },
    onBlur: e => {
      setIsFocussed(false);
      if (onBlur) {
        onBlur(e);
      }
      if (!value && showLegend) {
        legendRef.current.style.position = "absolute";
        legendRef.current.style.top = "17px";
        legendRef.current.style.transition = "";
        if (onBlurStyle.paddingLeft) {
          legendRef.current.style.paddingLeft = onBlurStyle.paddingLeft;
        }
      }
    },
    className: `input_box w-11/12  bg-white  
      rounded-l-md hover:border font-normal font-roboto text-sm px-4 border-grey-1009 ${
        type == "number" ? otherInput : ""
      }
       ${disabled ? "cursor-not-allowed text-black-600 " : ""}`,
    ref: inputRef,
  };

  const fieldSetClasses = classnames(
    `fieldset_box  h-full w-full rounded bg-white border ${
      !showLegend ? "border-grey-500" : "border-grey-1009"
    } 
        ${disabled ? " hover:border hover:border-grey-350 " : " "}`,
    `${
      error
        ? "hover:border hover:border-red-300 "
        : !showLegend
        ? "hover:border hover:border-green-2100 "
        : "hover:border hover:border-green-600 "
    }`,
    {
      " border border-red-300 ": error,
      " border border-green-600 ": !error && touched && isFocussed,
      " border border-green-2100 ":
        !error && touched && isFocussed && !showLegend,
    }
  );

  return (
    <div
      className={`input_container w-full py-auto ` + `${classNameProp}`}
      style={{
        ...stylesContainer,
        ...((value || isFocussed) && id !== "searchbar" && showLegend
          ? {
              height: "60px",
              marginTop: "-8px",
            }
          : {}),
      }}
    >
      <fieldset className={fieldSetClasses} style={fieldsetStyle}>
        {(!value && !isFocussed) || showLegend ? (
          <legend
            style={{
              position: value || isFocussed ? "relative" : "absolute",
              top: value || isFocussed ? "0px" : "17px",
              marginLeft: value || isFocussed ? "17px" : "24px",
              display: "inline-block",
              zIndex: 1,
              ...legendStyle,
            }}
            ref={legendRef}
            onClick={() => {
              inputRef.current.focus();
            }}
            className={`ml-6 border-black  ${
              value ? "text-xs" : "text-sm"
            } font-roboto text-black-600 font-nomal ${
              error ? "text-red-300" : ""
            }`}
          >
            {legendName}
          </legend>
        ) : null}

        <div
          className={`w-full flex items-center h-full ${
            searchIconPosition == "right" ? "flex-row-reverse" : "flex-row"
          } justify-items-start`}
        >
          {onClickSearch && (
            <div
              className="cursor-pointer text-black-400 px-2 h-full flex items-center"
              style={{ backgroundColor: searchIconStyle?.backgroundColor }}
              onClick={onClickSearch}
            >
              <Search style={{ color: searchIconStyle?.color }} />
            </div>
          )}
          <>
            {!disabled ? (
              <input {...commonInputProps} style={inputStyle} />
            ) : (
              <input {...commonInputProps} disabled style={inputStyle} />
            )}
          </>
          {showLoading && (
            <div
              className="absolute self-center content-center  "
              style={{
                width: "fit-content",
                right: "4px",
                ...(value || isFocussed ? { top: 0 } : { top: "9px" }),
              }}
            >
              <LoadingComponent />
            </div>
          )}
        </div>
      </fieldset>
      {error && errorMsg && (
        <div className="mt-1 text-xs text-red-300 ">{errorMsg}</div>
      )}
    </div>
  );
}

export default CommonInput;
