import React from 'react';
import './OuterContainer.css';

const OuterContainer = ({ show, Component, updatePassword }) => {
  return (
    <div className="pb-8 flex flex-row justify-center relative">
      <div className={updatePassword ? "max-w-3xl mx-auto px-5" : "w-full"}>
        <Component />
      </div>
    </div>
  );
};
export default OuterContainer;
