import React, { Fragment } from "react";
import useAxiosErrorHandler from "../../hooks/useAxiosErrorHandler";
import PopUp from "../../common/components/PopUp/PopUp";
import { LemsAPI } from "../../common/utils/api-utils";

const withErrorHandler = (WrapppedComponent, axios = LemsAPI) => {
  return props => {
    const [error, clearError] = useAxiosErrorHandler(axios);
    const propsRenderer = () => {
      let props = {};
      if (error && error?.response?.data?.status === 403) {
        props.type = "fail";
        props.text = Array.isArray(error?.response?.data?.subErrors)
          ? error?.response?.data?.subErrors[0]?.message
          : error?.response?.data?.subErrors ||
            error?.response?.data?.message ||
            "You are not authorized to view this document";
        props.dontClose = false;
        props.goto = false;
        return props;
      } else {
        props.type = "fail";
        props.text = Array.isArray(error?.response?.data?.subErrors)
          ? error?.response?.data?.subErrors[0]?.message
          : error?.response?.data?.subErrors ||
            error?.response?.data?.message ||
            "Oops! Something went wrong !";
        return props;
      }
    };

    return (
      <Fragment>
        {error && (
          <PopUp {...propsRenderer()} setShow={clearError} show={error} />
        )}
        <WrapppedComponent {...props} />
      </Fragment>
    );
  };
};

export default withErrorHandler;
