import React from "react";
import comingSoon from "../../images/icons/comingSoon.svg"
import { navigate } from "gatsby";
const ComingSoonPage = () =>{
    const navigateTo= () =>{
        navigate("/");
      }
    return(
       <div className="flex justify-center">
            <div className="py-40 lg:py-44">
                <div>
                    <center>
                        <img src={comingSoon} alt="ComingSoon"/>
                    </center>
                    <p className="text-center text-grey-920 font-bold text-lg -mt-8">Coming Soon</p>
                    <p className="text-center text-grey-920 text-md">This page is under construction</p>
                </div>
                <div className="flex justify-center pt-12">
                    <button className="py-2 px-12 text-lg font-bold rounded-sm"
                     style={{border:`1px solid #1F3C3D`,borderRadius:`2px`,color:`#1F3C3D`}}
                     onClick={navigateTo}>
                        Go Home
                    </button>
                </div>
            </div>
        </div>
    );
}
export default ComingSoonPage;