import React from "react";
import { getAuthToken } from "../../common/utils/cookie-utils";
//import * as Stomp from "@stomp/stompjs";

const Stomp = require("stompjs");
let callingWebSocketList = {};

export class webSocketConnection {
  //creates new websocket
  constructor() {
    (this.ws = null), (this.isDisconnected = false);
    this._id = webSocketConnection.incrementId();
    this.reconection = 0;
  }
  sendMessage = msg => {
    this.client.send(this.topic, {}, msg);
  };

  disconnect() {
    this.client.disconnect();
  }

  connect = ({
    url,
    onConnect,
    onDisconnect,
    onError,
    onMessage,
    topic,
    notificationWhatsAppTopic,
    token,
    notificationCount,
    notificationLead,
  }) => {
    let self = this;
    this.url = url;
    this.onConnect = onConnect;
    this.onDisconnect = onDisconnect;
    this.onError = onError;
    this.onMessage = onMessage;
    this.topic = topic;
    this.notificationWhatsAppTopic = notificationWhatsAppTopic;
    this.notificationLead - notificationLead;
    if (!token && !getAuthToken()) return;

    this.client = Stomp.over(new WebSocket(url));
    self.addToWebSocket();

    const successCallback = data => {
      typeof onConnect == "function" && onConnect(data);
      self.addToWebSocket();
      try {
        this.client.subscribe(topic, gotANewMessage);
        this.client.subscribe(notificationWhatsAppTopic, gotANewMessage);
        this.client.subscribe(notificationCount, gotANewMessage);
        this.client.subscribe(notificationLead, gotANewMessage);
      } catch {
        this.disconnect();
      }
    };

    const gotANewMessage = messageData => {
      typeof onMessage == "function" && onMessage(messageData);

      self.addToWebSocket();
    };

    const errorCallback = data => {
      typeof onDisconnect == "function" && onDisconnect();
      self.addToWebSocket();
    };

    this.client.ws.onclose = () => {
      typeof onDisconnect == "function" && onDisconnect();
    };

    this.client.connect(
      { token: token || getAuthToken() },
      successCallback,
      errorCallback
    );
  };
  addToWebSocket() {
    if (!this._id) return;
    callingWebSocketList[this._id] = this.client;
  }
  static incrementId() {
    if (!this.latestId) this.latestId = 1;
    else this.latestId++;
    return this.latestId;
  }

  reConnect() {
    ++this.reconection;
    this.connect({
      url: this.url,
      onConnect: this.onConnect,
      onDisconnect: this.onDisconnect,
      onError: this.onError,
      onMessage: this.onMessage,
      topic: this.topic,
      notificationWhatsAppTopic: this.notificationWhatsAppTopic,
    });
  }
}

export const closeAllSockets = () => {
  for (const [key, value] of Object.entries(callingWebSocketList)) {
    try {
      value.disconnect();
    } catch {
      continue;
    }
  }
};

export const isAliveWebSocket = () => {
  for (const [key, value] of Object.entries(callingWebSocketList)) {
    if (
      value?.connected ||
      value?.ws?.readyState == 0 ||
      value?.ws?.readyState == 1
    ) {
      return true;
    }
  }
  return false;
};
